<template>
  <div class="container is-flex is-justify-content-space-between">
    <div>
      <h1 class="is-size-3 title">Active Orders</h1>
      <div class="container is-flex mb-5">
        <div class="field mr-5">
          <label class="label">Search Item</label>
          <div class="select">
            <select v-model="searchField">
              <option value="supplierName">Supplier Name</option>
              <option value="locationName">Location Name</option>
              <option value="createdBy">Created By</option>
              <option value="status">Status</option>
            </select>
          </div>
        </div>

        <div v-if="searchField != 'status'" class="field mr-5">
          <label class="label">Search For</label>
          <input class="input" type="text" v-model="searchValue" />
        </div>
        <div v-else class="field mr-5">
          <label class="label">Search Item</label>
          <div class="select">
            <select v-model="searchValue">
              <option value="COMPLETED">COMPLETED</option>
              <option value="SCHEDULED">SCHEDULED</option>
              <option value="REQUESTED">REQUESTED</option>
              <option value="PLANNED">PLANNED</option>
            </select>
          </div>
        </div>

        <div class="field mt-5" v-if="searchValue">
          <div class="control">
            <button class="button is-light is-small" @click="searchValue = ''">
              Clear Filter
            </button>
          </div>
        </div>

        <div class="field">
          <label class="label">Requested Start Date</label>
          <div class="control">
            <VueDatePicker
              class="is-rounded"
              v-model="startDate"
              :enable-time-picker="false"
            ></VueDatePicker>
          </div>
        </div>
      </div>
    </div>

    <div>
      <button
        v-if="allowSchdule"
        @click="showScheduleCollection = true"
        class="button is-rounded is-warning mr-5"
      >
        Schedule Collections
      </button>
      <button
        v-if="selectedRequests.length && !allScheduled && !allowSchdule"
        class="button is-rounded is-success mr-5"
        :class="{ 'is-loading': isAcceptLoading }"
        @click="handleAccept"
      >
        Accept Collections
      </button>
      <button
        class="button is-rounded is-primary"
        v-if="!showForm"
        @click="showForm = true"
      >
        Create Collection Request
      </button>
    </div>
  </div>

  <div v-if="allOrders">
    <div
      v-if="selectedRequests.length"
      class="is-flex mb-5 is-justify-content-space-between"
    >
      <h1>
        No of selected requests: <b>{{ selectedRequests.length }}</b>
      </h1>
      <h1>
        Estimate total volume: <b> {{ totalVolume }} Liters</b>
      </h1>
    </div>
    <Vue3EasyDataTable
      :headers="headers"
      :items="allOrders"
      :search-field="searchField"
      :search-value="searchValue"
      table-class-name="customize-table"
      v-model:items-selected="selectedRequests"
      header-text-direction="center"
      body-text-direction="center"
      border-cell
      show-index
    >
      <template #item-supplierName="{ supplierName, id, supplierId }">
        <router-link
          :to="{
            name: 'OrderView',
            params: { id: id, supplierId: supplierId },
          }"
        >
          {{ supplierName }}
        </router-link>
      </template>
      <template #item-requestedDate="{ requestedDate }">
        {{ requestedDate.toDate().toDateString() }}
      </template>
      <template #item-status="{ status }">
        <span v-if="status == 'REQUESTED'" class="tag is-rounded">{{
          status
        }}</span>
        <span
          v-if="status == 'PLANNED'"
          class="tag is-rounded"
          :class="{ 'is-warning': status == 'PLANNED' }"
          >{{ status }}</span
        >
        <span
          v-if="status == 'SCHEDULED'"
          class="tag is-rounded"
          :class="{ 'is-success': status == 'SCHEDULED' }"
          >{{ status }}</span
        >
        <span
          v-if="status == 'COMPLETED'"
          class="tag is-rounded"
          :class="{ 'is-dark': status == 'COMPLETED' }"
          >{{ status }}</span
        >

        <span
          v-if="status == 'FAILED'"
          class="tag is-rounded"
          :class="{ 'is-danger': status == 'FAILED' }"
          >{{ status }}
        </span>
      </template>
      <template #item-scheduledDate="{ scheduledDate }">
        <p v-if="scheduledDate">
          {{ scheduledDate.toDate().toDateString() }}
        </p>
      </template>
      <template
        #item-flags="{ replaceContainer, highPriority, additionalContainer }"
      >
        <img
          class="mr-2"
          v-if="replaceContainer"
          src="@/assets/icons/refresh.png"
          alt=""
          style="height: 20px"
        />
        <img
          class="mr-2"
          v-if="highPriority"
          src="@/assets/icons/red-flag.png"
          alt=""
          style="height: 20px"
        />
        <img
          v-if="additionalContainer"
          src="@/assets/icons/plus.png"
          alt=""
          style="height: 20px"
        />
      </template>
      <template #item-operations="{ id, status, supplierId }">
        <img
          v-if="
            status != 'SCHEDULED' && status != 'COMPLETED' && status != 'FAILED'
          "
          class="mr-2"
          src="@/assets/icons/delete.png"
          alt=""
          style="height: 20px"
          @click="deleteRecord(id)"
        />
        <router-link
          :to="{
            name: 'OrderView',
            params: { id: id, supplierId: supplierId },
          }"
        >
          <img src="@/assets/icons/pencil.png" alt="" style="height: 20px" />
        </router-link>
      </template>
    </Vue3EasyDataTable>
  </div>

  <!-- Modals -->
  <div v-if="showForm">
    <CollecionRequestModal :showForm="showForm" @close="showForm = false" />
  </div>

  <div v-if="showDeleteRecordPopUp">
    <DeleteRecordDialog
      :title="deleteErrorText"
      :body="deleteErrorTextBody"
      :showModal="showDeleteRecordPopUp"
    />
  </div>

  <div v-if="showScheduleCollection">
    <ScheduleCollectionModal
      :showForm="showScheduleCollection"
      @close="showScheduleCollection = false"
      :collectionRequests="selectedRequests"
    />
  </div>
</template>

<script>
import { computed, ref, watchEffect, watch, onUnmounted } from "vue";
import CollecionRequestModal from "@/components/CollecionRequestModal.vue";
import ScheduleCollectionModal from "@/components/ScheduleCollectionModal.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import {
  deleteDoc,
  doc,
  updateDoc,
  Timestamp,
  collection,
  query,
  onSnapshot,
  orderBy,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "@/firebase/config";
import DeleteRecordDialog from "@/components/DeleteRecordDialog.vue";
import { useStore } from "vuex";

export default {
  setup() {
    const showForm = ref(false);
    const showScheduleCollection = ref(false);
    const isAcceptLoading = ref(false);
    const store = useStore();
    const startDate = ref(new Date());
    let unsubscribe = null;
    store.commit("setCurrentRoute", "Orders");

    // get the orders
    const allOrders = ref(null);

    watchEffect(() => {
      if (unsubscribe) {
        unsubscribe();
      }

      if (startDate.value) {
        try {
          let colRef = collection(db, "collectionRequests");

          const startOfTheDay = new Date(startDate.value);
          startOfTheDay.setHours(0, 0, 0, 0);

          const startTimestamp = Timestamp.fromDate(startOfTheDay);

          colRef = query(colRef, where("requestedDate", ">=", startTimestamp));

          unsubscribe = onSnapshot(colRef, (snapshot) => {
            let resuslts = [];

            snapshot.docs.forEach((doc) => {
              if (doc.data().siteId == store.state.currentSite) {
                resuslts.push({ ...doc.data(), id: doc.id });
              }
            });
            allOrders.value = resuslts;
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          const indexCreationUrl = error.message.match(
            /(http|https):\/\/[a-zA-Z0-9\/\.]+/
          );
          console.log("Index creation URL:", indexCreationUrl[0]);
        }
      }
    });

    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
    });

    const selectedRequests = ref([]);

    const showDeleteRecordPopUp = ref(false);
    const deleteErrorText = "Delete Collection Request";
    const deleteErrorTextBody = "Are you sure you want to remove the record?";

    const searchField = ref("");
    const searchValue = ref("");

    const totalVolume = ref(0);

    const headers = [
      { text: "Supplier Name", value: "supplierName", sortable: true },
      { text: "Supplier Location", value: "locationName", sortable: true },
      { text: "Created By", value: "createdBy", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Order Type", value: "orderType", sortable: true },
      { text: "Requested Date", value: "requestedDate", sortable: true },
      { text: "Planned Date", value: "scheduledDate", sortable: true },
      { text: "Flags", value: "flags" },
      { text: "Operations", value: "operations" },
    ];

    const deleteRecord = async (id) => {
      try {
        const path = "collectionRequests/" + id;
        const docRef = doc(db, path);
        await deleteDoc(docRef);
      } catch (error) {
        console.log(error.message);
      }
    };

    // Accept collection requests
    const handleAccept = async () => {
      isAcceptLoading.value = true;
      selectedRequests.value.forEach(async (request) => {
        const path = "collectionRequests/" + request.id;
        const docRef = doc(db, path);
        const updateData = {
          status: "PLANNED",
          scheduledDate: request.requestedDate,
          modifiedAt: Timestamp.now(),
          plannedAt: Timestamp.now(),
        };
        await updateDoc(docRef, updateData);
      });
      selectedRequests.value = [];
      isAcceptLoading.value = false;
    };

    const allowSchdule = computed(() => {
      var returnVal = false;
      selectedRequests.value.every((request) => {
        if (request.status != "PLANNED") {
          returnVal = false;
          return false;
        }
        returnVal = true;
        return true;
      });
      return returnVal;
    });

    watchEffect(() => {
      totalVolume.value = 0;
      if (selectedRequests.value.length) {
        for (let index = 0; index < selectedRequests.value.length; index++) {
          const request = selectedRequests.value[index];
          totalVolume.value = totalVolume.value + request.estimatedVolume;
        }
      }
      console.log(totalVolume.value);
    });

    const allScheduled = computed(() => {
      var returnVal = false;
      selectedRequests.value.every((request) => {
        if (request.status != "SCHEDULED") {
          returnVal = false;
          return false;
        }
        returnVal = true;
        return true;
      });
      return returnVal;
    });

    return {
      searchField,
      searchValue,

      showForm,
      allOrders,
      headers,
      selectedRequests,
      deleteRecord,
      showDeleteRecordPopUp,
      deleteErrorText,
      deleteErrorTextBody,

      handleAccept,
      isAcceptLoading,

      allowSchdule,
      showScheduleCollection,
      allScheduled,
      totalVolume,
      startDate,
      // filteredOrders,
    };
  },
  components: {
    CollecionRequestModal,
    Vue3EasyDataTable,
    DeleteRecordDialog,
    ScheduleCollectionModal,
    VueDatePicker,
  },
};
</script>

<style>
.customize-table {
  /* --easy-table-header-background-color: #2d3a4f;
    --easy-table-header-font-color: #c1cad4; */
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 30px;
}
</style>