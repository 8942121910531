import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import store from './store/index'

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import VueGoogleMaps from '@fawmi/vue-google-maps'

createApp(App)
    .use(store)
    .use(router)
    .component('EasyDataTable', Vue3EasyDataTable)
    .use(VueGoogleMaps, { load: { key: 'AIzaSyBP2ef3NaOOAit6z276ruULnd897zlubUc', libraries: "places" } })
    .mount('#app')
