<template>
  <section class="section">
    <div class="container" style="max-width: 400px">
      <div class="card">
        <!-- Form title -->
        <div class="card-header">
          <p class="card-header-title is-size-4">Sign Up</p>
        </div>

        <div class="card-content">
          <form @submit.prevent="handleSubmit">
            <!-- Display Name Section -->
            <div class="field">
              <label class="label">Display Name</label>
              <div class="control">
                <input
                  v-model="displayName"
                  class="input"
                  type="text"
                  placeholder="user@neutralfuels.com"
                  required
                />
              </div>
            </div>
            <!-- Email Section -->
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                  v-model="email"
                  class="input"
                  type="email"
                  placeholder="user@neutralfuels.com"
                  required
                />
              </div>
            </div>
            <!-- Password Section -->
            <div class="field">
              <label class="label">Password</label>
              <div class="control">
                <input
                  v-model="password"
                  class="input"
                  type="password"
                  placeholder="***********"
                  required
                />
              </div>
            </div>
            <!-- Select Country -->
            <div v-if="countries" class="field">
              <label class="label">Select Country</label>
              <div class="select control">
                <select v-model="selectedCoutnryId">
                  <option
                    :value="country.id"
                    v-for="country in countries"
                    :key="country.id"
                  >
                    {{ country.countryname }}
                  </option>
                </select>
              </div>
            </div>
            <!-- Select site -->
            <div v-if="sites" class="field">
              <label class="label">Select Site</label>
              <div class="select control">
                <select v-model="selectedSiteId">
                  <option
                    :value="{ siteId: site.id, siteName: site.sitename }"
                    v-for="site in sites"
                    :key="site.id"
                  >
                    {{ site.sitename }}
                  </option>
                </select>
              </div>
            </div>

            <!-- error section -->
            <div
              v-if="error"
              class="field has-text-centered is-size-7 has-text-danger"
            >
              {{ error }}
            </div>
            <!-- Login Button -->
            <div class="field has-text-centered">
              <div class="control">
                <button
                  class="button is-rounded is-info is-link"
                  :class="{ 'is-loading': isPending }"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
  
  <script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import getCollection from "@/composables/getCollection";
import { useStore } from "vuex";
export default {
  setup() {
    // Form variables
    const email = ref("");
    const password = ref("");
    const displayName = ref("");
    const selectedCoutnryId = ref(null);
    const selectedSiteId = ref(null);
    const error = ref(null);
    const isPending = ref(false);

    // composables
    const router = useRouter();
    const store = useStore();

    // Collection retrieved
    const { documents: countries } = getCollection("countries");
    const { documents: sites } = getCollection("sites");

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        await store.dispatch("signup", {
          email: email.value,
          password: password.value,
          displayName: displayName.value,
          countryId: selectedCoutnryId.value,
          siteId: selectedSiteId.value,
        });
        router.push({ name: "Orders" });
      } catch (err) {
        isPending.value = false;
        error.value = err.message;
        console.log(err.message);
      }
    };

    return {
      email,
      password,
      displayName,
      selectedSiteId,
      selectedCoutnryId,
      handleSubmit,
      error,
      isPending,
      countries,
      sites,
    };
  },
};
</script>
  
  <style>
</style>