<template>
  <div class="container">
    <h1 class="is-size-3 title">Users</h1>

    <div v-if="documents">     
        <EasyDataTable
            :headers="headers"
            :items="documents"
            header-text-direction="center"
            body-text-direction="center"
            table-class-name="customize-table"
            border-cell
            show-index
        >
            <template #item-operations="{id}">
                <router-link :to="{ name: 'UserView', params: {id: id}}">
                    <img src="@/assets/icons/pencil.png" alt="" style="height:20px;"/>
                </router-link>
            </template>
            <template #item-createdAt="{ createdAt }">
                {{ createdAt.toDate().toDateString() }}
            </template>
            <template #item-sites="{ sites }">
                {{ sites.length }}
            </template>
        </EasyDataTable>
        
    </div>
  </div>
</template>

<script>
import getCollection from '@/composables/getCollection'
import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
    setup() {
        const { documents } = getCollection('users')
        const headers = [
            { text: 'Display Name', value: 'displayName', sortable: true},
            { text: 'Email', value: 'email', sortable: true},
            { text: 'Role', value: 'userRole', sortable: true},
            { text: 'Created At', value: 'createdAt', sortable: true},
            { text: 'Allowed Sites', value: 'sites', sortable: true},
            { text: 'Operations', value: 'operations'},

        ]
        console.log(documents)

        return { documents, headers}
    },
    components: [ Vue3EasyDataTable,  ],
}
</script>

<style>

</style>