<template v-if="authIsReady">
  <Navbar/>
  <!-- View for authenticated users -->
  <div v-if="(user && userProfile)" class="columns mx-4 my-4">
    <div class="column is-narrow">
      <div class="box">
        <SideNavbar/>
      </div>
    </div>
    <div class="column">
      <div class="box">
        <router-view/>
      </div>
    </div>
  </div>
  <!-- View for unauthenticated users -->
  <div v-if="!user">
    <router-view/>
  </div>
  <Footer/>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue';
import SideNavbar from './components/SideNavbar.vue';
import { useStore } from 'vuex';
import { computed } from '@vue/runtime-core';

export default{
  setup() {
    const store = useStore()

    return { 
      user: computed(() => store.state.user),
      authIsReady: computed(() => store.state.authIsReady),
      userProfile: computed(() => store.state.userProfile)
    }
  },
  components: { Navbar, Footer, SideNavbar }
}
</script>

<style>
  html, body {
    height: 100%;
  }
</style>
