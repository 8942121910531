<template>
    <div class="modal" :class="{ 'is-active': showModal }">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <div class="is-flex is-justify-content-space-between">
                    <p class="modal-card-title">{{ title }}</p>
                </div>
            </header>
            <section class="modal-card-body">
                <p class="is-size-6">
                    {{ body }}
                </p>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-rounded is-danger" @click="closeModal">Yes</button>
                <button class="button is-rounded is-primary" @click="closeModal">No</button>
            </footer>
        </div>
    </div>
    
</template>

<script>
export default {
    props: [ 'title', 'body', 'showModal' ],
    setup(props, context) {
        const closeModal = (b) => {
            
        }
    }
}
</script>

<style>

</style>