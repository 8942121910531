<template>
   <div v-if="!document">
        <spinner-view/>
  </div>
  <div v-else>
    <nav class="breadcrumb is-small" aria-label="breadcrumbs">
      <ul>
        <li> 
          <router-link :to="{name: 'Users'}">Users</router-link> 
        </li>
        <li class="is-active"><a href="#" aria-current="page">{{ document.displayName }}</a></li>
      </ul>
    </nav>
    <div class="container">
      <h1 class="is-size-3 title">{{ document.displayName }}</h1>
      <div class="columns">
        <div class="column">
          <div class="container box">
            <p class="title is-size-5">User Details</p>
            <p class="mb-1">
                <b>UID: </b>
                {{ document.uid }}
            </p>
            <p class="mb-1">
                <b>Display Name: </b>
                {{ document.displayName }}
            </p>
            <p class="mb-1">
                <b>Email: </b>
                {{ document.email }}
            </p>
            <p class="mb-1">
                <b>User Role: </b>
                {{ document.userRole }}
            </p>
            <p class="mb-1">
                <b>Created At: </b>
                {{ document.createdAt.toDate().toDateString() }}
            </p>
            <p class="mb-1">
                <b>Modified At: </b>
                {{ document.updatedAt.toDate().toDateString() }}
            </p>
          </div>  
        </div>
        <div class="column">
          <div class="box">
            <p class="title is-size-5">Sites</p>
            <div v-for="site in sites" :key="site.id">
              {{  site.sitename }}
            </div>
          </div> 
          
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import SpinnerView from '@/components/SpinnerView.vue';
import { computed, ref, watchEffect } from 'vue';
import { doc, onSnapshot } from '@firebase/firestore';
import { db } from '@/firebase/config';
import getCollection from '@/composables/getCollection';

export default {
  components: { SpinnerView },
    props: ['id' ],
    setup(props) {
    const error = ref(null)
    const document = ref(null)

    // Getting a ref to the document
    let docRef = doc(db, 'users', props.id)
    const { documents: sites } = getCollection('sites')

    const userSites = computed(() => {
      sites.value.forEach(site => {
        console.log(site)
      });
    })

    const unsub = onSnapshot(docRef, doc => {
        if (doc.data()) {
            console.log('Data Received: ', doc.data())
            document.value = { ...doc.data() }
            error.value = null
        } else {
            error.value = 'That docuemnt does not exsist'
        }
    }, (err) => {
        console.log(err.message)
        error.value = 'Could not fetch the document'
        document.value = null
    })

    watchEffect((onInvalidate) => {
        // unsubscribe from the realtime listner
        onInvalidate(() => unsub())
    })
            
        return { document, sites, userSites }
    }
}
</script>

<style>

</style>