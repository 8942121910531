<template>
    <div class="container is-flex is-justify-content-space-between">
        <h1 class="is-size-3 title">Containers</h1>
        <button class="button is-rounded is-primary" @click="showNewContainerModal = true">Add Container</button>
    </div>
    <div v-if="containers">
        <Vue3EasyDataTable
            :headers="headers" 
            :items="containers"
            header-text-direction="center"
            body-text-direction="center"
            border-cell
            show-index
        >
        </Vue3EasyDataTable>
    </div>
    
    <!-- Add New Container Modal -->
    <div class="modal" :class=" {'is-active': showNewContainerModal}">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">New Container</p>
                <button class="delete" aria-label="close" @click="showNewContainerModal = false"></button>
            </header>
            <section class="modal-card-body">
                <form>
                    <div class="field">
                        <label class="label">Container Type</label>
                        <div class="control">
                            <input v-model="containerType" class="input" type="text">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Max Volume</label>
                        <div class="control">
                            <input v-model="maxVolume" class="input" type="number">
                        </div>
                    </div>
                </form>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-rounded is-success" :class="{ 'is-loading': isPending}" @click="addContainer">Add Container</button>
                <button class="button is-rounded" @click="showNewContainerModal = false">Cancel</button>
            </footer>
        </div>
    </div>
</template>

<script>
import getCollection from '@/composables/getCollection';
import useCollection from '@/composables/useCollection';
import { ref } from 'vue';
import Vue3EasyDataTable from 'vue3-easy-data-table'

export default {
    components: { Vue3EasyDataTable },
    setup() {
        const { documents: containers } = getCollection('containers')
        const { addDoucment, error, isPending } = useCollection('containers')

        // Modal Controllers
        const showNewContainerModal = ref(false)
        const showEditContainerModal = ref(false)

        // New Form Variables
        const containerType = ref('')
        const maxVolume = ref('')
        const updateContainerId = ref(null)

        // Table Headers
        const headers = [
            { text: 'Container Type', value: 'containerType', sortable: true },
            { text: 'Volume', value: 'maxVolume' },
            { text: 'Operations', value: 'operations' }
        ]

        // Add container method
        const addContainer = async () => {
            const containerObject = {
                containerType: containerType.value,
                maxVolume: maxVolume.value
            }
            console.log(containerObject)
            await addDoucment(containerObject)
            if (!error.value) {
                containerType.value = ''
                maxVolume.value = ''
                showNewContainerModal.value = false
            }
        }

        return { containers, headers, showNewContainerModal, containerType, maxVolume, addContainer, isPending, showEditContainerModal }
    }
}
</script>

<style>

</style>