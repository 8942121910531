<template>
  <div>
    <SpinnerView v-if="isLoading" />
    <div v-else>
      <div class="container is-flex is-justify-content-space-between">
        <h1 class="is-size-3 title">Zones</h1>
        <button
          class="button is-rounded is-primary"
          @click="createNewZone = true"
        >
          Create New Zone
        </button>
      </div>
      <div class="">
        <GMapMap
          class="mb-5"
          :zoom="11"
          :center="{ lat: 25.150398, lng: 55.255297 }"
          map-type-id="terrain"
          style="width: 100%; height: 45rem"
          :options="{
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
          }"
        >
          <div v-for="zone in data" :key="zone.id">
            <GMapPolygon :paths="zone.areas" />
            <GMapInfoWindow
              :options="{
                content: zone.zoneName,
                position: getPolygonCenter(zone.areas),
                disableAutoPan: true,
              }"
            />
          </div>
        </GMapMap>
      </div>
      <Vue3EasyDataTable
        :items="data"
        :headers="headers"
        header-text-direction="center"
        body-text-direction="center"
        table-class-name="customize-table"
        border-cell
        show-index
      >
        <template #item-areas="{ areas }">
          <div>
            <div v-for="(area, index) in areas" :key="index">
              <b>Lat:</b> {{ area.lat }}, <b>Lng:</b> {{ area.lng }}
            </div>
          </div>
        </template>
        <template #item-operations="{ zoneId }">
          <router-link
            :to="{
              name: 'ZoneView',
              params: { id: zoneId },
            }"
          >
            <img src="@/assets/icons/pencil.png" alt="" style="height: 20px" />
          </router-link>
        </template>
      </Vue3EasyDataTable>

      <div class="modal" :class="{ 'is-active': createNewZone }">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Create New Zone</p>
            <button
              class="delete"
              aria-label="close"
              @click="createNewZone = false"
            ></button>
          </header>
          <section class="modal-card-body">
            <div class="field">
              <label class="label">Zone Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Zone Name"
                  v-model="newZone.zoneName"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Zone Description</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Zone Description"
                  v-model="newZone.zoneDescription"
                />
              </div>
            </div>
            <div
              class="field"
              v-for="(area, index) in newZone.areas"
              :key="index"
            >
              <label class="label">Geo Bound {{ index + 1 }}</label>
              <div class="field has-addons">
                <div class="control">
                  <input
                    class="input"
                    type="number"
                    placeholder="Latitude"
                    v-model="area.lat"
                  />
                </div>
                <div class="control">
                  <input
                    class="input ml-2"
                    type="number"
                    placeholder="Longitude"
                    v-model="area.lng"
                  />
                </div>
                <div class="control ml-5">
                  <button class="button is-danger" @click="removeArea(index)">
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div class="field">
              <button class="button is-primary" @click="addArea">
                Add Area
              </button>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button is-success"
              :disabled="isSubmitting"
              @click="submitNewZone"
            >
              {{ isSubmitting ? "Loading..." : "Create" }}
            </button>
            <button class="button" @click="createNewZone = false">
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerView from "@/components/SpinnerView.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  setup() {
    const store = useStore();
    const isLoading = ref(true);
    const data = ref([]);
    const isSubmitting = ref(false);

    const createNewZone = ref(false);
    const newZone = ref({
      zoneName: "",
      zoneDescription: "",
      areas: [],
    });

    const submitNewZone = async () => {
      isSubmitting.value = true;
      const apiUrl = store.state.apiUrl + "/createZone";
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            zoneName: newZone.value.zoneName,
            siteId: store.state.currentSite,
            zoneDescription: newZone.value.zoneDescription,
            areas: newZone.value.areas,
          }),
        });

        if (response.ok) {
          // Handle successful response here
          console.log("New zone created successfully");
        } else {
          // Handle error response here
          console.log("Failed to create new zone");
        }
      } catch (error) {
        // Handle network error here
        console.log("Network error:", error);
      } finally {
        isSubmitting.value = false;
        createNewZone.value = false;
        await getZoneData();
      }
    };

    const addArea = () => {
      newZone.value.areas.push({ lat: 0, lng: 0 });
    };

    const removeArea = (index) => {
      newZone.value.areas.splice(index, 1);
    };

    const getZoneData = async () => {
      const apiUrl =
        store.state.apiUrl + "/getZonePlannerData/" + store.state.currentSite;
      try {
        const response = await fetch(apiUrl);
        if (response.ok) {
          const result = await response.json();
          data.value = result;
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const getPolygonCenter = (paths) => {
      let lat = 0;
      let lng = 0;

      paths.forEach((point) => {
        lat += point.lat;
        lng += point.lng;
      });

      lat /= paths.length;
      lng /= paths.length;

      return { lat, lng };
    };

    const headers = [
      { text: "Zone", value: "zoneName" },
      { text: "Zone Description", value: "zoneDescription" },
      { text: "Areas", value: "areas" },
      { text: "Operations", value: "operations" },
    ];

    getZoneData();

    return {
      isLoading,
      data,
      createNewZone,
      newZone,
      headers,
      getPolygonCenter,
      submitNewZone,
      addArea,
      isSubmitting,
      removeArea,
    };
  },
  components: {
    SpinnerView,
    Vue3EasyDataTable,
  },
};
</script>

<style>
/* CSS to hide the close button in InfoWindow */
.gm-ui-hover-effect {
  display: none !important;
}
</style>