<template>
  <div v-if="!document">
    <spinner-view />
  </div>
  <div v-else>
    <nav class="breadcrumb is-small" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Suppliers' }">Suppliers</router-link>
        </li>
        <li class="is-active">
          <a href="#" aria-current="page">{{ document.suppliername }}</a>
        </li>
      </ul>
    </nav>
    <div class="container">
      <div
        class="is-flex is-align-self-center is-justify-content-space-between"
      >
        <h1 class="is-size-3 title">{{ document.suppliername }}</h1>
        <button
          class="button is-rounded is-danger is-small ml-5"
          :class="{ 'is-loading': activeResetSupplier }"
          @click="resetSupplier"
        >
          Reset
        </button>
      </div>

      <!-- Supplier Details and Contact Information -->
      <div class="columns">
        <!-- Supplier Details -->
        <div class="column">
          <div class="box">
            <p class="title is-size-5">Supplier Details</p>
            <p class="mb-1">
              <b>Supplier ID: </b>
              {{ document.supplierid }}
            </p>
            <p class="mb-1">
              <b>Supplier Number: </b>
              {{ document.suppliernumber }}
            </p>
            <p class="mb-1">
              <b>Supplier Name: </b>
              {{ document.suppliername }}
            </p>

            <p class="mb-1" v-if="!document.locations.length">
              <b>Zone Name: </b>
              <span v-if="document.zoneName != null">{{
                document.zoneName
              }}</span>
              <span v-else>Unlisted</span>
            </p>
          </div>
        </div>

        <!-- Contact Information -->
        <div class="column">
          <div class="box">
            <p class="title is-size-5">Contact Information</p>
            <p class="mb-1">
              <b>Contact Person: </b>
              {{ document.contactname }}
            </p>
            <p class="mb-1">
              <b>Email Address: </b>
              {{ document.contactemailaddress }}
            </p>
            <p class="mb-1">
              <b>Phone Number: </b>
              {{ document.contacttelephone }}
            </p>
          </div>
        </div>
      </div>

      <!-- Settings and Addresses -->
      <div class="columns">
        <!-- Address -->
        <div
          v-if="
            document.geotag != 'NIL' &&
            document.geotag != null &&
            !document.locations.length
          "
          class="column"
        >
          <div class="box">
            <h2 class="title is-size-5">Location Map</h2>
            <GoogleMap
              api-key="AIzaSyAhURDudHSVdIaghKlgWaE4BNAw_hdzPSE"
              style="width: 100%; height: 690px"
              :center="center"
              :zoom="15"
            >
              <Marker :options="{ position: center }" />
            </GoogleMap>
          </div>
        </div>

        <!-- Supplier Settings -->
        <div class="column">
          <!-- Recurring Collection Section -->
          <div v-if="!document.locations.length" class="box">
            <p class="title is-size-5">Recurring Collection</p>
            <form @submit.prevent="updateReccuring">
              <div class="container is-flex">
                <div>
                  <label class="label">Weekday</label>
                  <div class="select control">
                    <select v-model="recurringWeekday" required>
                      <option
                        :value="weekday.value"
                        v-for="weekday in weekdays"
                        :key="weekday.value"
                      >
                        {{ weekday.weekday }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="ml-4">
                  <label class="label">Duration</label>
                  <div class="select control">
                    <select v-model="recurringDuration" required>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                </div>
                <div class="ml-4">
                  <label class="label">Frequency</label>
                  <div class="select control">
                    <select v-model="recurringFrequency" required>
                      <option value="1">Days</option>
                      <option value="7">Weeks</option>
                    </select>
                  </div>
                </div>
                <div class="ml-4">
                  <label class="label">Start Date</label>
                  <div class="control">
                    <VueDatePicker
                      required
                      class="is-rounded"
                      v-model="recurringStartDate"
                      :min-date="new Date()"
                      :disabled-week-days="[5]"
                      :enable-time-picker="false"
                    ></VueDatePicker>
                  </div>
                </div>
              </div>
              <div v-if="document.nextSchedule">
                <p class="label mt-4">
                  Next Scheulde Date:
                  {{ document.nextSchedule.toDate().toDateString() }}
                </p>
              </div>
              <button
                class="mt-4 button is-rounded is-success"
                :class="{ 'is-loading': recurringButtonLoading }"
              >
                {{ recurringWeekday != null ? "Update" : "Save" }}
              </button>
            </form>
          </div>

          <!-- Service Windows Section -->
          <div v-if="!document.locations.length" class="box">
            <p class="title is-size-5">Service Windows</p>
            <form @submit.prevent="updateServiceWindows">
              <div class="container is-flex">
                <div>
                  <label class="label">Start Time</label>
                  <div class="select control">
                    <select v-model="serviceStartTime" required>
                      <option
                        :value="time.value"
                        v-for="time in timeWindows"
                        :key="time.value"
                      >
                        {{ time.time }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="ml-4">
                  <label class="label">End Time</label>
                  <div class="select control">
                    <select v-model="serviceEndTime" required>
                      <option
                        :value="time.value"
                        v-for="time in timeWindows"
                        :key="time.value"
                      >
                        {{ time.time }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <button
                class="mt-4 button is-rounded is-success"
                :class="{ 'is-loading': serviceTimeButtonLoading }"
              >
                {{ serviceStartTime != null ? "Update" : "Save" }}
              </button>
            </form>
          </div>

          <!-- Containers section -->
          <div v-if="!document.locations.length" class="box">
            <div class="is-flex is-justify-content-space-between">
              <p class="title is-size-5">Assets</p>
              <img
                class="mr-2"
                src="@/assets/icons/plus.png"
                alt=""
                style="height: 20px"
                @click="showForm = true"
              />
            </div>

            <div v-if="document.containers">
              <Vue3EasyDataTable
                :headers="containerHeaders"
                :items="document.containers"
                header-text-direction="center"
                body-text-direction="center"
                border-cell
              >
                <template #item-operations="{ containerType }">
                  <img
                    class="mr-2"
                    src="@/assets/icons/delete.png"
                    alt=""
                    style="height: 20px"
                    @click="deleteRecord(containerType)"
                  />
                </template>
              </Vue3EasyDataTable>
            </div>
            <div v-else>
              <Vue3EasyDataTable
                :headers="containerHeaders"
                :items="[]"
                header-text-direction="center"
                body-text-direction="center"
                border-cell
              >
              </Vue3EasyDataTable>
            </div>
          </div>
        </div>
      </div>

      <!-- Locations Sections -->
      <div v-if="document.locations.length" class="columns">
        <div class="column">
          <div class="box">
            <p class="title is-size-5">Locations</p>
            <div>
              <Vue3EasyDataTable
                :headers="headers"
                :items="document.locations"
                header-text-direction="center"
                body-text-direction="center"
                border-cell
              >
                <template #item-locationid="{ locationid }">
                  <router-link
                    :to="{
                      name: 'LocationView',
                      params: {
                        locationId: locationid,
                        supplierId: document.supplierid,
                      },
                    }"
                  >
                    {{ locationid }}
                  </router-link>
                </template>

                <template
                  #item-serviceWindowStartTime="{ serviceWindowStartTime }"
                >
                  {{
                    serviceWindowStartTime
                      ? serviceWindowStartTime + ":00"
                      : "Not Set"
                  }}
                </template>

                <template #item-serviceWindowEndTime="{ serviceWindowEndTime }">
                  {{
                    serviceWindowEndTime
                      ? serviceWindowEndTime + ":00"
                      : "Not Set"
                  }}
                </template>
              </Vue3EasyDataTable>
            </div>
          </div>
        </div>
      </div>

      <!-- Past Orders -->
      <div v-if="pastOrders" class="columns">
        <div class="column">
          <div class="box">
            <p class="title is-size-5">Past Orders</p>
            <EasyDataTable
              :headers="pastOrderHeaders"
              :items="pastOrders"
              header-text-direction="center"
              body-text-direction="center"
              border-cell
            >
              <template #item-id="{ id }">
                <!-- TODO: Update the URL to point to the actual server  -->
                <a
                  :href="
                    'https://nf-test-oms.neutralfuels.net/dashboard/operations/order-view/' +
                    id
                  "
                >
                  {{ id }}</a
                >
                <!-- <a @click="routeTo(id)">{{ id }}</a> -->
              </template>

              <template #item-createdAt="{ createdAt }">
                {{ createdAt.toDate().toLocaleString() }}
              </template>

              <template #item-collectedAt="{ collectedAt }">
                {{
                  collectedAt
                    ? collectedAt.toDate().toLocaleString()
                    : "Pending"
                }}
              </template>

              <template #item-status="{ status }">
                <span
                  v-if="status == 'REQUESTED'"
                  class="tag is-rounded"
                  :class="{ 'is-danger': status == 'REQUESTED' }"
                  >{{ status }}</span
                >
                <span
                  v-if="status == 'PLANNED'"
                  class="tag is-rounded"
                  :class="{ 'is-warning': status == 'PLANNED' }"
                  >{{ status }}</span
                >
                <span
                  v-if="status == 'SCHEDULED'"
                  class="tag is-rounded"
                  :class="{ 'is-success': status == 'SCHEDULED' }"
                  >{{ status }}</span
                >
                <span
                  v-if="status == 'COMPLETED'"
                  class="tag is-rounded"
                  :class="{ 'is-dark': status == 'COMPLETED' }"
                  >{{ status }}</span
                >
              </template>
            </EasyDataTable>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <div v-if="showForm">
      <AddContainer
        :showForm="showForm"
        @close="showForm = false"
        :supplierId="document.supplierid"
        :locationId="0"
      />
    </div>
  </div>
</template>

<script>
import SpinnerView from "@/components/SpinnerView.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import { GoogleMap, Marker } from "vue3-google-map";
import AddContainer from "@/components/AddContainer.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { useStore } from "vuex";
import {
  collection,
  doc,
  onSnapshot,
  updateDoc,
  setDoc,
  query,
  where,
} from "@firebase/firestore";
import { db } from "@/firebase/config";
import { ref, watchEffect } from "vue";

export default {
  components: {
    SpinnerView,
    Vue3EasyDataTable,
    GoogleMap,
    Marker,
    AddContainer,
    VueDatePicker,
  },
  props: ["id"],
  setup(props) {
    const store = useStore();
    store.commit("setCurrentRoute", "Suppliers");
    const document = ref(null);
    const pastOrders = ref(null);
    const center = ref(null);
    const showForm = ref(false);
    // const selectedLocationId = ref(0)
    const recurringWeekday = ref(null);
    const recurringDuration = ref(null);
    const recurringFrequency = ref(null);
    const recurringStartDate = ref(null);
    const recurringButtonLoading = ref(false);
    const dateList = ref([]);

    const serviceStartTime = ref(null);
    const serviceEndTime = ref(null);
    const serviceTimeButtonLoading = ref(false);

    const activeResetSupplier = ref(false);

    const weekdays = [
      { value: 0, weekday: "Sunday" },
      { value: 1, weekday: "Monday" },
      { value: 2, weekday: "Tuesday" },
      { value: 3, weekday: "Wednesday" },
      { value: 4, weekday: "Thursday" },
      { value: 6, weekday: "Saturday" },
    ];

    const timeWindows = [
      { value: 6, time: "06:00" },
      { value: 7, time: "07:00" },
      { value: 8, time: "08:00" },
      { value: 9, time: "09:00" },
      { value: 10, time: "10:00" },
      { value: 11, time: "11:00" },
      { value: 12, time: "12:00" },
      { value: 13, time: "13:00" },
      { value: 14, time: "14:00" },
      { value: 15, time: "15:00" },
      { value: 16, time: "16:00" },
      { value: 17, time: "17:00" },
      { value: 18, time: "18:00" },
    ];

    // watch(recurringWeekday, () => {
    //   console.log("Weekday selected list updated");
    //   const dates = [];
    //   const today = new Date();
    //   today.setHours(0, 0, 0, 0);
    //   for (let index = 1; index < 30; index++) {
    //     const day = new Date(today);
    //     day.setDate(day.getDate() + index);
    //     const weekday = day.getDay();
    //     if (recurringWeekday.value === weekday) {
    //       console.log("Found a matching week", day);
    //       const timeStampDay = Timestamp.fromDate(day);
    //       dates.push({
    //         date: timeStampDay.toDate().toDateString(),
    //         timestamp: timeStampDay,
    //       });
    //     }
    //   }
    //   dateList.value = dates;
    // });

    let docRef = doc(db, "suppliers", props.id);
    let colRefOrders = collection(db, "collectionRequests");
    colRefOrders = query(colRefOrders, where("supplierId", "==", props.id));

    let unsub = onSnapshot(docRef, (doc) => {
      if (doc.data()) {
        console.log(doc.data());
        if (
          doc.data().geotag != "NIL" &&
          doc.data().geotag != null &&
          !doc.data().locations.length
        ) {
          let url = store.state.apiUrl + "/getLatLng/" + doc.data().geotag;
          fetch(url)
            .then((res) => res.json())
            .then((data) => (center.value = data));
        }
        if (doc.data().frequency) {
          console.log("Has frequency data");
          recurringDuration.value = doc.data().duration;
          recurringFrequency.value = doc.data().frequency;
          recurringWeekday.value = doc.data().recurringWeekday;
          recurringStartDate.value = doc.data().recurringStartDate;
        }

        if (doc.data().serviceWindowStartTime) {
          console.log("Has service time windows");
          serviceEndTime.value = doc.data().serviceWindowEndTime;
          serviceStartTime.value = doc.data().serviceWindowStartTime;
        }
        document.value = { ...doc.data() };
      }
    });

    let unsub2 = onSnapshot(colRefOrders, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        if (document.value) {
          if (doc.data().supplierId == props.id) {
            results.push({ ...doc.data(), id: doc.id });
          }
        }
      });

      pastOrders.value = results;
    });

    const deleteRecord = async (containerType) => {
      var containers = [];

      document.value.containers.forEach((container) => {
        if (container.containerType != containerType) {
          containers.push(container);
        }
      });

      await updateDoc(docRef, { containers: containers });
    };

    watchEffect((onInvalidate) => {
      onInvalidate(() => {
        unsub();
        unsub2();
      });
    });

    const updateReccuring = async () => {
      recurringButtonLoading.value = true;

      const recurringOrder = {
        duration: parseInt(recurringDuration.value),
        frequency: parseInt(recurringFrequency.value),
        lastSchedule: null,
        nextSchedule: recurringStartDate.value,
        recurringStartDate: recurringStartDate.value,
        recurringWeekday: recurringWeekday.value,
      };

      await setDoc(docRef, recurringOrder, { merge: true });
      console.log(recurringOrder);

      recurringButtonLoading.value = false;
    };

    const updateServiceWindows = async () => {
      serviceTimeButtonLoading.value = true;

      const serviceWindows = {
        serviceWindowStartTime: serviceStartTime.value,
        serviceWindowEndTime: serviceEndTime.value,
      };

      await setDoc(docRef, serviceWindows, { merge: true });
      console.log(serviceWindows);

      serviceTimeButtonLoading.value = false;
    };

    const resetSupplier = async () => {
      activeResetSupplier.value = true;
      let url =
        store.state.apiUrl + "/hardResetSupplier/" + document.value.supplierid;
      let res = await fetch(url);
      if (res.status == 200) {
        activeResetSupplier.value = false;
      }
    };

    const headers = [
      { text: "Location ID", value: "locationid" },
      { text: "Location Name", value: "locationname" },
      { text: "Geo Tag", value: "areaname" },
      { text: "Start Time", value: "serviceWindowStartTime" },
      { text: "End Time", value: "serviceWindowEndTime" },
    ];

    const containerHeaders = [
      { text: "Conatiner Type", value: "containerType" },
      { text: "Volume", value: "maxVolume" },
      { text: "Quantity", value: "quantity" },
      { text: "Operations", value: "operations" },
    ];

    const pastOrderHeaders = [
      { text: "Order ID", value: "id" },
      { text: "Requested Date", value: "createdAt" },
      { text: "Collected Date", value: "collectedAt" },
      { text: "Volume", value: "actualVolume" },
      { text: "Status", value: "status" },
    ];

    return {
      document,
      headers,
      center,
      containerHeaders,
      pastOrderHeaders,
      pastOrders,
      showForm,
      deleteRecord,
      recurringWeekday,
      weekdays,
      recurringFrequency,
      updateReccuring,
      recurringStartDate,
      dateList,
      recurringButtonLoading,
      recurringDuration,
      timeWindows,
      serviceStartTime,
      serviceEndTime,
      serviceTimeButtonLoading,
      updateServiceWindows,
      resetSupplier,
      activeResetSupplier,
    };
  },
};
</script>

<style>
</style>