<template>
  <div v-if="!document">
    <spinner-view />
  </div>

  <div v-else>
    <!-- Breadcrumbs -->
    <nav class="breadcrumb is-small" aria-label="breadcrumb">
      <ul>
        <li>
          <router-link :to="{ name: 'Planning' }">Planning</router-link>
        </li>
        <li class="is-active">
          <a href="#" aria-current="page">{{ document.route_id }}</a>
        </li>
      </ul>
    </nav>

    <!-- Route Details  -->
    <div>
      <h2 class="is-size-4">Route Details</h2>
      <div class="columns">
        <div class="column box my-5 mx-3">
          <div class="my-3">
            <p><b>ID:</b> {{ document.route_id }}</p>
            <p>
              <b>Driver Name:</b> {{ document.member_first_name }}
              {{ document.member_last_name }}
            </p>
            <p>
              <b>No. of Collections:</b> {{ document.addresses.length - 2 }}
            </p>
            <p><b>Total Volume:</b> {{ document.route_cube }} L</p>
            <p><b>Route Type:</b> {{ document.nfRouteType }}</p>
          </div>
        </div>
        <div class="column box my-5 mx-3">
          <button
            class="button is-rounded is-primary"
            :class="{ 'is-loading': updatingRoute }"
            @click="updateRoute(document.route_id)"
          >
            Update Route
          </button>
        </div>
      </div>

      <div class="box">
        <h2 class="is-size-5">Manifest</h2>
        <div class="mt-5">
          <EasyDataTable
            :headers="headers"
            :items="document.addresses"
            header-text-direction="center"
            body-text-direction="center"
            border-cell
            show-index
          >
            <template
              #item-additional_status="{
                is_depot,
                additional_status,
                is_visited,
              }"
            >
              <div v-if="is_depot">
                <p class="tag" :class="{ 'is-dark': is_depot }">Depot</p>
              </div>
              <div v-else-if="additional_status">
                <p
                  v-if="additional_status == 'Failed'"
                  class="tag"
                  :class="{ 'is-danger': additional_status == 'Failed' }"
                >
                  {{ additional_status }}
                </p>
                <p
                  v-if="additional_status == 'Completed'"
                  class="tag"
                  :class="{ 'is-success': additional_status == 'Completed' }"
                >
                  {{ additional_status }}
                </p>
              </div>
              <div v-else-if="is_visited">
                <p class="tag" :class="{ 'is-info': is_visited }">Arrived</p>
              </div>
              <div v-else>
                <p class="tag is-warning">Pending</p>
              </div>
            </template>
            <template #item-actualInOut="{ manifest }">
              <div v-if="manifest.actual_arrival_time_ts">
                <p>
                  <b>In: </b
                  >{{
                    new Date(
                      manifest.actual_arrival_time_ts * 1000
                    ).toLocaleTimeString()
                  }}
                </p>
                <p>
                  <b>Out: </b
                  >{{
                    new Date(
                      manifest.actual_departure_time_ts * 1000
                    ).toLocaleTimeString()
                  }}
                </p>
              </div>
            </template>

            <template #item-estimatedInOut="{ manifest }">
              <div v-if="manifest.estimated_arrival_time_ts">
                <p>
                  <b>In: </b
                  >{{
                    new Date(
                      manifest.estimated_arrival_time_ts * 1000
                    ).toLocaleTimeString()
                  }}
                </p>
                <p>
                  <b>Out: </b
                  >{{
                    new Date(
                      manifest.estimated_departure_time_ts * 1000
                    ).toLocaleTimeString()
                  }}
                </p>
              </div>
            </template>

            <template #item-scheduledInOut="{ manifest }">
              <div v-if="manifest.scheduled_arrival_time_ts">
                <p>
                  <b>In: </b
                  >{{
                    new Date(
                      manifest.scheduled_arrival_time_ts * 1000
                    ).toLocaleTimeString()
                  }}
                </p>
                <p>
                  <b>Out: </b
                  >{{
                    new Date(
                      manifest.scheduled_departure_time_ts * 1000
                    ).toLocaleTimeString()
                  }}
                </p>
              </div>
            </template>

            <template #item-reference_no="{ reference_no }">
              <div v-if="reference_no">
                <router-link
                  :to="{
                    name: 'OrderView',
                    params: { id: reference_no, supplierId: 'None' },
                  }"
                >
                  {{ reference_no }}
                </router-link>
              </div>
            </template>
          </EasyDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase/config";
import { doc, onSnapshot } from "@firebase/firestore";
import { ref, watchEffect } from "vue";

import SpinnerView from "@/components/SpinnerView.vue";
import { useStore } from "vuex";

export default {
  components: { SpinnerView },
  props: ["id"],
  setup(props) {
    const store = useStore();
    const document = ref(null);

    let docRef = doc(db, "plannedRoutes", props.id);

    let unSub = onSnapshot(docRef, (doc) => {
      if (doc.data()) {
        document.value = { ...doc.data() };
      }
    });

    watchEffect((onInvalidate) => {
      onInvalidate(() => unSub());
    });

    const updatingRoute = ref(false);
    const updateRoute = async (id) => {
      updatingRoute.value = true;
      let url = store.state.apiUrl + "/getRoute/" + id;
      let res = await fetch(url);
      if (res.status == 200) {
        updatingRoute.value = false;
      }
    };

    const headers = [
      { text: "Stop Name", value: "alias" },
      { text: "Actual In/Out", value: "actualInOut" },
      { text: "Estimated In/Out", value: "estimatedInOut" },
      { text: "Scheduled In/Out", value: "scheduledInOut" },
      { text: "Volume", value: "weight" },
      { text: "Order Number", value: "reference_no" },
      { text: "Status", value: "additional_status" },
    ];

    return { document, headers, updatingRoute, updateRoute };
  },
};
</script>

<style>
</style>