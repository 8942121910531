<template>
  <div class="container" v-if="!isLoading">
    <div class="columns">
      <div class="column">
        <h2 class="title is-2">Edit Zone</h2>
        <form>
          <div class="field">
            <label class="label" for="zone-name">Zone Name:</label>
            <div class="control">
              <input
                class="input"
                type="text"
                id="zone-name"
                v-model="zoneName"
              />
            </div>
          </div>
          <div class="field">
            <label class="label" for="zone-description">
              Zone Description:
            </label>
            <div class="control">
              <input
                class="input"
                type="text"
                id="zone-description"
                v-model="zoneDescription"
              />
            </div>
          </div>
          <div
            class="field"
            v-for="(area, index) in zoneData.areas"
            :key="index"
          >
            <label class="label">Geo Bound {{ index + 1 }}</label>
            <div class="field has-addons">
              <div class="control">
                <input
                  class="input"
                  type="number"
                  placeholder="Latitude"
                  v-model="area.lat"
                />
              </div>
              <div class="control">
                <input
                  class="input"
                  type="number"
                  placeholder="Longitude"
                  v-model="area.lng"
                />
              </div>
              <div class="control">
                <button class="button is-danger" @click="removeArea(index)">
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div class="field">
            <button class="button is-primary" @click="addArea">Add Area</button>
          </div>
          <!-- <div class="control">
            <label class="label" for="areas">Zone Map:</label>
            <GMapAutocomplete
              placeholder="Search for a location"
              @place_changed="setPlace"
              style="font-size: medium"
              class="input"
            >
            </GMapAutocomplete>
          </div> -->

          <button class="button mt-5" type="submit">Save</button>
        </form>
      </div>
      <div class="column">
        <GMapMap
          class="mt-5"
          :zoom="12"
          :center="coords"
          map-type-id="terrain"
          style="width: 100%; height: 35rem"
          :options="{
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
          }"
        >
          <GMapPolygon :paths="zoneData.areas" />
        </GMapMap>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  props: ["id"],
  setup(props) {
    const zoneName = ref("");
    const zoneDescription = ref("");
    const zoneMarkers = ref([]);
    const coords = ref({ lat: 0, lng: 0 });
    const locationDetails = ref({ address: "", url: "" });
    const placeSelected = ref(false);
    const store = useStore();
    const isLoading = ref(true);
    const zoneData = ref({});

    // Set the location based on the place selected
    const setPlace = (place) => {
      coords.value.lat = place.geometry.location.lat();
      coords.value.lng = place.geometry.location.lng();
      placeSelected.value = true;
      // Update the location details
      locationDetails.value.address = place.formatted_address;
      locationDetails.value.url = place.url;
    };

    const getZoneData = async () => {
      const apiUrl =
        store.state.apiUrl + "/getZonePlannerData/" + store.state.currentSite;
      try {
        const response = await fetch(apiUrl);
        if (response.ok) {
          const result = await response.json();
          const zone = result.find((zone) => zone.zoneId == props.id);
          if (zone) {
            zoneName.value = zone.zoneName;
            zoneDescription.value = zone.zoneDescription;
            zoneMarkers.value = zone.areas;
            zoneData.value = zone;
            coords.value = calculateCenter();
          }
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        isLoading.value = false;
      }
    };

    getZoneData();

    const calculateCenter = () => {
      if (zoneData.value.areas.length === 0) {
        return { lat: 0, lng: 0 };
      }

      const total = zoneData.value.areas.reduce(
        (acc, area) => {
          return {
            lat: acc.lat + area.lat,
            lng: acc.lng + area.lng,
          };
        },
        { lat: 0, lng: 0 }
      );

      return {
        lat: total.lat / zoneData.value.areas.length,
        lng: total.lng / zoneData.value.areas.length,
      };
    };

    watch(
      zoneData.value.areas,
      () => {
        // The polygon will automatically update because it's bound to the areas array with the :paths attribute
      },
      { deep: true }
    );

    const addArea = () => {
      zoneData.value.areas = [...zoneData.value.areas, { lat: 0, lng: 0 }];
    };

    const removeArea = (index) => {
      zoneData.value.areas = [
        ...zoneData.value.areas.slice(0, index),
        ...zoneData.value.areas.slice(index + 1),
      ];
    };

    return {
      zoneName,
      zoneDescription,
      zoneMarkers,
      setPlace,
      coords,
      placeSelected,
      isLoading,
      zoneData,
      addArea,
      removeArea,
    };
  },
};
</script>

