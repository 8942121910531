<template>
  <section class="section">
    <div class="container" style="max-width: 400px;">
      <div class="card">
        <!-- Form title -->
        <div class="card-header">
          <p class="card-header-title is-size-4">
            Login
          </p>
        </div>

        <div class="card-content">
          <form @submit.prevent="handleSubmit">
            <!-- Email Section -->
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input v-model="email" class="input" type="email" placeholder="user@neutralfuels.com" required>
              </div>
            </div>
            <!-- Password Section -->
            <div class="field">
              <label class="label">Password</label>
              <div class="control">
                <input v-model="password" class="input" type="password" placeholder="***********" required>
              </div>
            </div>
            <div v-if="error" class="field has-text-centered is-size-7 has-text-danger">
              {{ error }}
            </div>
            <!-- Login Button -->
            <div class="field has-text-centered">
              <div class="control">
                <button class="button is-rounded is-info is-link" :class="{ 'is-loading': isPending }">Login</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  setup() {
    const email = ref('')
    const password = ref('')
    const error = ref(null)
    const isPending = ref(false)

    // const { isPending, login, error } = useLogin()
    const router = useRouter()
    const store = useStore()

    const handleSubmit = async () => {
      try {
        isPending.value = true
        await store.dispatch('login', {
          email: email.value, 
          password: password.value
        })
        router.push({name: 'Orders'})
      } catch (err) {
        isPending.value = false
        error.value = err.message
        console.log(err.message)
      }
    }

    return { email, password, handleSubmit, error, isPending }
  }
}
</script>

<style>

</style>