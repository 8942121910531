<template>
  <div class="container">
    <div class="conatainer is-flex is-justify-content-space-between">
      <h1 class="is-size-3 title">All Suppliers</h1>
      <!-- <button
        class="button is-rounded is-primary"
        :class="{ 'is-loading': updatingSuppliers }"
        @click="updateSupplierList"
      >
        Update Supplier List
      </button> -->
    </div>
    <div class="container is-flex mb-5">
      <div class="field mr-5">
        <label class="label">Search Item</label>
        <div class="select">
          <select v-model="searchField">
            <option value="suppliername">Supplier Name</option>
            <option value="contactname">Contact Name</option>
            <option value="contactemailaddress">Email Address</option>
          </select>
        </div>
      </div>

      <div class="field mr-5">
        <label class="label">Search For</label>
        <input class="input" type="text" v-model="searchValue" />
      </div>
    </div>

    <div v-if="suppliers">
      <EasyDataTable
        :headers="headers"
        :items="suppliers"
        :search-field="searchField"
        :search-value="searchValue"
        header-text-direction="center"
        body-text-direction="center"
        table-class-name="customize-table"
        border-cell
        show-index
      >
        <template #item-locations="{ locations }">
          {{ locations.length }}
        </template>
        <template #item-suppliername="{ suppliername, id }">
          <router-link :to="{ name: 'SupplierView', params: { id: id } }">
            {{ suppliername }}
          </router-link>
        </template>
        <template #item-operations="{ id }">
          <router-link :to="{ name: 'SupplierView', params: { id: id } }">
            <img src="@/assets/icons/pencil.png" alt="" style="height: 20px" />
          </router-link>
        </template>
      </EasyDataTable>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase/config";
import { collection, onSnapshot, orderBy, query } from "@firebase/firestore";
import { ref } from "@vue/runtime-core";
import { watchEffect } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    store.commit("setCurrentRoute", "Suppliers");
    const suppliers = ref(null);
    const searchField = ref("suppliername");
    const searchValue = ref("");

    let colRef = collection(db, "suppliers");
    colRef = query(colRef, orderBy("suppliername"));

    const unSub = onSnapshot(colRef, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        if (doc.data().siteid == store.state.currentSite) {
          results.push({ ...doc.data(), id: doc.id });
        }
      });

      suppliers.value = results;
    });

    watchEffect((onInvalidate) => {
      onInvalidate(() => unSub());
    });

    const updatingSuppliers = ref(false);
    const updateSupplierList = async () => {
      updatingSuppliers.value = true;
      let url = store.state.apiUrl + "/updateSupplierList";
      let res = await fetch(url);
      if (res.status == 200) {
        updatingSuppliers.value = false;
      }
    };

    const headers = [
      { text: "Supplier Name", value: "suppliername" },
      { text: "Contact Name", value: "contactname" },
      { text: "Contact Email", value: "contactemailaddress" },
      { text: "No of Locations", value: "locations" },
      { text: "Geo Tag", value: "geotag", sortable: true },
      { text: "Operations", value: "operations" },
    ];

    return {
      suppliers,
      headers,
      updateSupplierList,
      updatingSuppliers,
      searchField,
      searchValue,
    };
  },
};
</script>

<style>
</style>