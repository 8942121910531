<template>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="is-flex is-justify-content-space-between">
          <p class="modal-card-title">New Collection Request</p>
        </div>
      </header>
      <section class="modal-card-body">
        <!-- <section class="section"> -->
        <form>
          <!-- select supplier -->
          <div class="field">
            <label class="label">Supplier</label>
            <div class="select control">
              <select v-model="selectedSupplierId" required>
                <option
                  :value="supplier.id"
                  v-for="supplier in suppliers"
                  :key="supplier.id"
                >
                  {{ supplier.suppliername }}
                </option>
              </select>
            </div>
          </div>
          <!-- Show the section after the supplier is selected -->
          <div v-if="selectedSupplier">
            <!-- select supplier location if any location available -->
            <div v-if="locations.length" class="field">
              <label class="label">Location</label>
              <div class="select control">
                <select v-model="selectedLocationId" required>
                  <option
                    :value="location.locationid"
                    v-for="location in locations"
                    :key="location.locationid"
                  >
                    {{ location.locationname }}
                  </option>
                </select>
              </div>
            </div>
            <!-- collection date - next 7 days only -->
            <div class="field">
              <label class="label">Request Date</label>
              <div class="select control">
                <select v-model="requestDate" required>
                  <option
                    :value="date.timestamp"
                    v-for="date in dateList"
                    :key="date.date"
                  >
                    {{ date.date }}
                  </option>
                </select>
              </div>
            </div>
            <div class="field mb-5">
              <label class="label">Additional Requirments</label>
              <div class="control">
                <label class="checkbox ml-5">
                  <input type="checkbox" v-model="replaceContainer" /> Replace
                  Container
                </label>
                <label class="checkbox ml-5">
                  <input type="checkbox" v-model="highPriority" /> High Priority
                </label>
                <label class="checkbox ml-5">
                  <input type="checkbox" v-model="additionalConatiner" />
                  Additional Container
                </label>
              </div>
            </div>
            <div class="field">
              <label class="label"
                >Estimated Volume
                <input
                  type="text"
                  class="input"
                  disabled
                  :value="estimatedVolume"
                />
              </label>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-success"
          :class="{ 'is-loading': isPending }"
          @click="handleClick"
        >
          Create
        </button>
        <button class="button" @click="closeModal">Cancel</button>
      </footer>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "@firebase/firestore";
import { computed, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import useCollection from "@/composables/useCollection";
import { db } from "@/firebase/config";

export default {
  props: ["showForm"],
  setup(props, context) {
    const suppliers = ref(null);

    // store import
    const store = useStore();
    const site = store.state.currentSite;

    // import suppliers
    let colRef = collection(db, "suppliers");
    colRef = query(colRef, orderBy("suppliername"));
    // colRef = query(colRef, orderBy('suppliername'))

    const unSub = onSnapshot(colRef, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        if (doc.data().siteid == store.state.currentSite) {
          results.push({ ...doc.data(), id: doc.id });
        }
      });

      // update values
      suppliers.value = results;
    });

    watchEffect((onInvalidate) => {
      onInvalidate(() => unSub());
    });
    // const { documents: suppliers } = getCollection('suppliers', ['siteid', '==', store.state.currentSite])
    const { addDoucment, error, isPending } =
      useCollection("collectionRequests");

    // form fields
    const selectedSupplierId = ref(null);
    const selectedLocationId = ref(null);
    const geotag = ref(null);
    const estimatedVolume = ref(null);

    const requestDate = ref(null);
    const dateList = ref([]);

    async function fetchDates(supplierId, locationId) {
      try {
        const apiUrl =
          store.state.apiUrl +
          "/getSupplierLocationPlannerData/" +
          supplierId +
          "/" +
          locationId;
        console.debug("Fetching dates from:", apiUrl);
        const response = await fetch(apiUrl);

        if (response.ok) {
          const isoDates = await response.json();
          console.debug(isoDates);
          dateList.value = isoDates.map((isoDate) => {
            const date = new Date(isoDate);
            return {
              date: date.toDateString(),
              timestamp: Timestamp.fromDate(date),
            };
          });
        }
      } catch (err) {
        console.error("Error fetching dates:", err);
      }
    }

    watch(
      [selectedSupplierId, selectedLocationId],
      ([newSupplierId, newLocationId]) => {
        if (newSupplierId) {
          const effectiveLocationId = newLocationId ? newLocationId : 0;
          fetchDates(newSupplierId, effectiveLocationId);
        } else {
          dateList.value = [];
        }
      }
    );

    // Additional requirments field
    const replaceContainer = ref(false);
    const highPriority = ref(false);
    const additionalConatiner = ref(false);

    // supplier and location fields
    const selectedLocation = ref(null);
    const selectedSupplier = ref(null);
    const locations = ref(null);

    watch(selectedSupplierId, () => {
      selectedSupplier.value = null;
      selectedLocationId.value = null;
      selectedLocation.value = null;
      locations.value = null;
      suppliers.value.forEach((supplier) => {
        if (supplier.id == selectedSupplierId.value) {
          selectedSupplier.value = { ...supplier };
          locations.value = supplier.locations;
          geotag.value = supplier.geotag;
          if (supplier.locations.length == 0) {
            if (supplier.containers) {
              var vol = 0;
              supplier.containers.forEach((container) => {
                vol = vol + container.maxVolume * container.quantity * 0.8;
              });

              estimatedVolume.value = vol;
            } else {
              estimatedVolume.value = 150;
            }
          }
          console.log(estimatedVolume.value);
        }
      });
    });

    watch(selectedLocationId, () => {
      locations.value.forEach((location) => {
        if (location.locationid == selectedLocationId.value) {
          selectedLocation.value = { ...location };
          geotag.value = location.areaname;
          if (selectedSupplier.value.containers) {
            var vol = 0;
            selectedSupplier.value.containers.forEach((container) => {
              if (container.locationId == selectedLocationId.value) {
                vol = vol + container.maxVolume * container.quantity * 0.8;
              }
            });

            estimatedVolume.value = vol;
          } else {
            estimatedVolume.value = 150;
          }
        }
      });
    });

    // Close modal
    const closeModal = () => {
      context.emit("close");
    };

    const handleClick = async () => {
      const collectionRequest = {
        supplierName: selectedSupplier.value.suppliername,
        supplierId: selectedSupplierId.value,
        locationId: selectedLocationId.value ? selectedLocationId.value : 0,
        locationName: selectedLocationId.value
          ? selectedLocation.value.locationname
          : null,
        createdAt: Timestamp.now(),
        orderType: "COLLECTION",
        createdBy: store.state.user.displayName,
        siteId: selectedSupplier.value.siteid,
        countryid: selectedSupplier.value.countryid,
        geotag: geotag.value,
        requestedBy: "Neutral Fuels",
        status: "REQUESTED",
        modifiedAt: Timestamp.now(),
        requestedDate: requestDate.value,
        scheduledDate: null,
        replaceContainer: replaceContainer.value,
        highPriority: highPriority.value,
        additionalContainer: additionalConatiner.value,
        estimatedVolume: estimatedVolume.value,
      };

      await addDoucment(collectionRequest);
      if (!error.value) {
        closeModal();
      }
    };

    return {
      // Form Fields
      selectedSupplierId,
      selectedSupplier,
      selectedLocationId,
      requestDate,
      replaceContainer,
      highPriority,
      additionalConatiner,
      isPending,

      // Emits
      closeModal,

      // Lists
      suppliers,
      dateList,
      locations,

      // Methods
      handleClick,
      estimatedVolume,
    };
  },
};
</script>

<style>
</style>