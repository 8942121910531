<template>
  <div class="columns">
    <div class="column mx-5 my-5">
      <div class="form">
        <div class="field">
          <label class="label">Supplier</label>
          <div class="select control is-rounded">
            <select v-model="selectedSupplierId">
              <option
                :value="supplier.id"
                v-for="supplier in suppliers"
                :key="supplier.id"
              >
                {{ supplier.suppliername }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="selectedSupplierId">
          <div v-if="locations.length" class="field">
            <label class="label">Location</label>
            <div class="select control is-rounded">
              <select v-model="selectedLocationId">
                <option
                  :value="location.locationid"
                  v-for="location in locations"
                  :key="location.locationid"
                >
                  {{ location.locationname }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column has-text-centered">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div v-if="value">
              <p class="title is-size-4">Customer Code</p>
              <QrcodeVue class="mb-4" :value="value" :size="qrSize" level="L" />
            </div>
          </div>
          <div class="column">
            <div v-if="value">
              <p class="title is-size-4">Loading Area Code</p>
              <QrcodeVue
                class="mb-4"
                :value="loadingAreaValue"
                :size="qrSize"
                level="L"
              />
            </div>
          </div>
        </div>
        <div v-if="value">
          <br />
          <a :href="value" target="_blank">
            <button class="button is-rounded">View Customer Page</button>
          </a>
          <br />
          <div class="mt-5">
            <button class="button is-rounded" @click="createOTUCode">
              Create OTU Code
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="column has-text-centered"></div>
  </div>
  <div class="columns">
    <div class="column">
      <div class="box">
        <p class="title is-size-5">Active Codes</p>
        <Vue3EasyDataTable :headers="headers" :items="codes">
          <template #item-createdAt="{ createdAt }">
            <p v-if="createdAt">
              {{ createdAt.toDate().toDateString() }}
            </p>
          </template>

          <template #item-operations="{ id }">
            <img
              class="mr-2"
              src="@/assets/icons/delete.png"
              alt=""
              style="height: 20px"
              @click="deleteRecord(id)"
            />
          </template>
        </Vue3EasyDataTable>
      </div>
    </div>
  </div>
</template>

<script>
import useCollection from "@/composables/useCollection";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  Timestamp,
  query,
  orderBy,
} from "@firebase/firestore";
import QrcodeVue from "qrcode.vue";
import { ref, watch, watchEffect } from "vue";
import { db } from "@/firebase/config";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();

    const suppliers = ref(null);

    // import suppliers
    let colRefSup = collection(db, "suppliers");
    colRefSup = query(colRefSup, orderBy("suppliername"));

    const unSub2 = onSnapshot(colRefSup, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        if (doc.data().siteid == store.state.currentSite) {
          results.push({ ...doc.data(), id: doc.id });
        }
      });

      suppliers.value = results;
    });
    // const { documents: suppliers } = getCollection('suppliers')

    // Use collection for codes
    const { addDoucment } = useCollection("generatedCodes");

    const codes = ref([]);
    // collection reference
    let colRef = collection(db, "generatedCodes");
    const unsub = onSnapshot(colRef, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });

      // update values
      codes.value = results;
    });

    // Table headers
    const headers = [
      { text: "ID", value: "id", sortable: true },
      { text: "Supplier Name", value: "supplierName", sortable: true },
      { text: "Supplier Location", value: "locationName", sortable: true },
      { text: "Created At", value: "createdAt", sortable: true },
      { text: "Operations", value: "operations" },
    ];

    const url = "https://merchant.neutralfuels.com";
    const localUrl = "http://localhost:8081/";
    const value = ref(null);
    const loadingAreaValue = ref(null);
    const qrSize = 300;

    // form fields
    const selectedSupplierId = ref(null);
    const selectedLocationId = ref(null);

    // supplier and location fields
    const selectedLocation = ref(null);
    const selectedSupplier = ref(null);
    const locations = ref(null);

    watch(selectedSupplierId, () => {
      value.value = null;
      loadingAreaValue.value = null;
      selectedSupplier.value = null;
      selectedLocationId.value = null;
      selectedLocation.value = null;
      locations.value = null;
      suppliers.value.forEach((supplier) => {
        if (supplier.id == selectedSupplierId.value) {
          selectedSupplier.value = { ...supplier };
          locations.value = supplier.locations;
        }
      });
      if (!locations.value.length) {
        selectedLocation.value = {};
        const qrValue =
          url +
          "?supplierid=" +
          selectedSupplierId.value +
          "&locationid=" +
          "0";
        const loadingQrValue =
          "?supplierid=" + selectedSupplierId.value + "&locationid=" + "0";
        value.value = qrValue;
        loadingAreaValue.value = loadingQrValue;
      }
    });

    watch(selectedLocationId, () => {
      locations.value.forEach((location) => {
        if (location.locationid == selectedLocationId.value) {
          selectedLocation.value = { ...location };
          const qrValue =
            url +
            "?supplierid=" +
            selectedSupplierId.value +
            "&locationid=" +
            selectedLocationId.value;
          const loadingQrValue =
            "?supplierid=" +
            selectedSupplierId.value +
            "&locationid=" +
            selectedLocationId.value;
          loadingAreaValue.value = loadingQrValue;
          value.value = qrValue;
        }
      });
    });

    watchEffect((onInvalidate) => {
      onInvalidate(() => {
        unsub();
        unSub2();
      });
    });

    const createOTUCode = async () => {
      const dataObject = {
        createdAt: Timestamp.now(),
        supplierId: selectedSupplierId.value,
        locationId: selectedLocationId.value ? selectedLocationId.value : "0",
        supplierName: selectedSupplier.value.suppliername,
        locationName: selectedLocationId.value
          ? selectedLocation.value.locationname
          : null,
      };
      await addDoucment(dataObject);
    };

    const deleteRecord = async (id) => {
      try {
        const path = "generatedCodes/" + id;
        const docRef = doc(db, path);
        await deleteDoc(docRef);
      } catch (error) {
        console.log(error.message);
      }
    };

    return {
      value,
      loadingAreaValue,
      selectedLocationId,
      selectedSupplierId,
      suppliers,
      locations,
      qrSize,
      createOTUCode,
      codes,
      headers,
      deleteRecord,
    };
  },
  components: { QrcodeVue, Vue3EasyDataTable },
};
</script>

<style>
</style>