<template>
  <aside class="menu">
    <!-- Operations Section -->
    <p class="menu-label">Operations</p>
    <ul class="menu-list">
      <li>
        <a
          @click="handleRouting('Orders')"
          :class="{ 'is-active': currentPath == 'Orders' }"
          >Orders</a
        >
      </li>
      <li>
        <a
          @click="handleRouting('Planning')"
          :class="{ 'is-active': currentPath == 'Planning' }"
          >Planning</a
        >
      </li>
      <li>
        <a
          @click="handleRouting('ZonePlanning')"
          :class="{ 'is-active': currentPath == 'ZonePlanning' }"
          >Zone Planning</a
        >
      </li>
    </ul>
    <!-- Admin Section -->
    <p class="menu-label">Administration</p>
    <ul class="menu-list">
      <li>
        <a
          @click="handleRouting('Suppliers')"
          :class="{ 'is-active': currentPath == 'Suppliers' }"
          >Suppliers</a
        >
      </li>
      <li>
        <a
          @click="handleRouting('Inventory')"
          :class="{ 'is-active': currentPath == 'Inventory' }"
          >Inventroy</a
        >
      </li>
      <!-- <li><a class="disable-link" @click="handleRouting('Route4Me')" :class="{ 'is-active': currentPath == 'Route4Me' }">Route4Me</a></li> -->
      <li>
        <a
          @click="handleRouting('CodeGenerator')"
          :class="{ 'is-active': currentPath == 'CodeGenerator' }"
          >Code Generator</a
        >
      </li>
      <!-- <li><a>Authentication</a></li> -->
    </ul>
    <!-- Settings Section -->
    <p class="menu-label">Settings</p>
    <ul class="menu-list">
      <li>
        <a
          class="disable-link"
          @click="handleRouting('Sites')"
          :class="{ 'is-active': currentPath == 'Sites' }"
          >Sites</a
        >
      </li>
      <li>
        <a
          @click="handleRouting('Containers')"
          :class="{ 'is-active': currentPath == 'Containers' }"
          >Containers</a
        >
      </li>
      <li>
        <a
          @click="handleRouting('Zones')"
          :class="{ 'is-active': currentPath == 'Zones' }"
          >Zones</a
        >
      </li>
      <li>
        <a
          @click="handleRouting('Users')"
          :class="{ 'is-active': currentPath == 'Users' }"
          >Users</a
        >
      </li>
    </ul>
  </aside>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const currentPath = ref(store.state.currentRoute);
    const router = useRouter();

    const handleRouting = (route) => {
      router.push({ name: route });
      store.commit("setCurrentRoute", route);
      currentPath.value = store.state.currentRoute;
    };

    return { handleRouting, currentPath };
  },
};
</script>

<style>
.disable-link {
  pointer-events: none;
}
</style>