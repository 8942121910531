<template>
  <div class="container">
    <h1 class="is-size-3 title">Route Planning</h1>
    <div class="conatainer is-flex is-justify-content-space-between">
      <div>
        <h2 class="is-size-5">No. of Routes: {{ plannedRoutes.length }}</h2>
        <h2 class="is-size-5">Total Volume: {{ totalVolume }} L</h2>
        <h2 class="is-size-5">No. of Collections: {{ totalStops }}</h2>
      </div>
      <div>
        <button
          class="button is-rounded is-dark is-small"
          @click="minusDay(date)"
        >
          Back
        </button>
        <span class="mx-2 is-size-6">
          <b>{{ date.toLocaleDateString() }}</b>
        </span>
        <button
          class="button is-rounded is-dark is-small"
          @click="addDay(date)"
        >
          Next
        </button>
      </div>
    </div>

    <div v-if="plannedRoutes.length">
      <div
        class="card px-5 py-5 my-3"
        v-for="route in plannedRoutes"
        :key="route.route_id"
      >
        <div class="conatainer is-flex is-justify-content-space-between">
          <p class="is-size-5">
            <router-link :to="{ name: 'RouteView', params: { id: route.id } }">
              Truck No: {{ route.nfTruckNumber }}
            </router-link>
          </p>
          <div>
            <router-link :to="{ name: 'RouteView', params: { id: route.id } }">
              <button
                class="button is-rounded is-light ml-3"
                @click="route.showManifest = false"
              >
                View Manifest
              </button>
            </router-link>
          </div>
        </div>
        <div class="my-3">
          <p class="is-size-5"><b>Route Details</b></p>
          <p><b>ID:</b> {{ route.id }}</p>
          <p>
            <b>Driver Name:</b> {{ route.member_first_name }}
            {{ route.member_last_name }}
          </p>
          <p><b>No. of Collections:</b> {{ route.addresses.length - 2 }}</p>
          <p><b>Total Volume:</b> {{ route.route_cube }} L</p>
          <p><b>Route Type:</b> {{ route.nfRouteType }}</p>
        </div>

        <!-- <div v-if="route.showManifest">
                    <EasyDataTable
                        :headers="headers"
                        :items="route.addresses"
                        header-text-direction="center"
                        body-text-direction="center"
                        border-cell
                        show-index
                    >
                        <template #item-additional_status="{is_depot, additional_status, is_visited}">
                            <div v-if="is_depot">
                                <p class="tag" :class="{ 'is-dark':is_depot }">Depot</p>
                            </div>
                            <div v-else-if="additional_status">
                                <p  v-if="additional_status == 'Failed'" class="tag" :class="{ 'is-danger':additional_status == 'Failed'}">{{ additional_status }}</p>
                                <p v-if="additional_status == 'Completed'" class="tag" :class="{ 'is-success':additional_status == 'Completed'}">{{ additional_status }}</p>
                            </div>
                            <div v-else-if="is_visited">
                                <p class="tag" :class="{ 'is-info':is_visited }">Arrived</p>
                            </div>
                            <div v-else>
                                <p class="tag is-warning" >Pending</p>
                            </div>
                        </template>
                        <template #item-actualInOut="{ manifest }">
                            <div v-if="manifest.actual_arrival_time_ts">
                                <p><b>In: </b>{{ new Date(manifest.actual_arrival_time_ts * 1000 ).toLocaleTimeString()  }} </p>
                                <p><b>Out: </b>{{ new Date(manifest.actual_departure_time_ts * 1000 ).toLocaleTimeString() }}</p>
                            </div>
                        </template>

                        <template #item-estimatedInOut="{ manifest }">
                            <div v-if="manifest.estimated_arrival_time_ts">
                                <p><b>In: </b>{{ new Date(manifest.estimated_arrival_time_ts * 1000 ).toLocaleTimeString() }} </p>
                                <p><b>Out: </b>{{ new Date(manifest.estimated_departure_time_ts * 1000 ).toLocaleTimeString() }}</p>
                            </div>
                        </template>

                        <template #item-scheduledInOut="{ manifest }">
                            <div v-if="manifest.scheduled_arrival_time_ts">
                                <p><b>In: </b>{{ new Date(manifest.scheduled_arrival_time_ts * 1000 ).toLocaleTimeString() }} </p>
                                <p><b>Out: </b>{{ new Date(manifest.scheduled_departure_time_ts * 1000 ).toLocaleTimeString() }}</p>
                            </div>
                        </template>

                        <template #item-reference_no="{ reference_no }">
                            <div v-if="reference_no">
                                <router-link :to="{ name: 'OrderView', params: { id: reference_no}}">
                                    {{ reference_no }}
                                </router-link>
                            </div>
                            
                        </template>

                    </EasyDataTable>
                </div> -->
      </div>
    </div>
    <div v-else class="columns">
      <div class="column has-text-centered is-vcentered">
        No planned routes for <b>{{ date.toDateString() }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase/config";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import { computed } from "@vue/reactivity";
import { ref, watchEffect } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const date = ref(new Date());
    const dateString = ref(date.value.toLocaleDateString());
    const todayDate = ref(date.value.toLocaleDateString());

    const allRoutes = ref(null);

    // collection referecne
    let colRef = collection(db, "plannedRoutes");
    colRef = query(colRef, where("siteId", "==", store.state.currentSite));
    const unsub = onSnapshot(colRef, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id, showManifest: true });
      });

      // update Route values
      allRoutes.value = results;
    });

    const plannedRoutes = computed(() => {
      let results = [];
      if (allRoutes.value) {
        allRoutes.value.forEach((route) => {
          if (route.schduledDate.includes("T")) {
            var _date = route.schduledDate.split("T");
            var _scheduleDate = new Date(_date[0]);
            // console.log(
            //   _scheduleDate.toLocaleDateString(),
            //   todayDate.value,
            //   _scheduleDate.toLocaleDateString() == todayDate.value
            // );
            if (_scheduleDate.toLocaleDateString() == todayDate.value) {
              results.push({ ...route });
            }
          } else {
            // console.log(
            //   route.schduledDate,
            //   todayDate.value,
            //   route.schduledDate == todayDate.value
            // );
            if (route.schduledDate == todayDate.value) {
              results.push({ ...route });
            }
          }
        });
      }
      // console.log(results);
      return results;
    });

    watchEffect((onInvalidate) => {
      onInvalidate(() => unsub());
    });

    const headers = [
      { text: "Stop Name", value: "alias" },
      { text: "Actual In/Out", value: "actualInOut" },
      { text: "Estimated In/Out", value: "estimatedInOut" },
      { text: "Scheduled In/Out", value: "scheduledInOut" },
      { text: "Volume", value: "weight" },
      { text: "Order Number", value: "reference_no" },
      { text: "Status", value: "additional_status" },
    ];

    const totalVolume = computed(() => {
      let value = 0;
      if (plannedRoutes) {
        plannedRoutes.value.forEach((route) => {
          value = value + route.route_cube;
        });
      }
      return value;
    });

    const totalStops = computed(() => {
      let value = 0;
      if (plannedRoutes) {
        plannedRoutes.value.forEach((route) => {
          value = value + (route.addresses.length - 2);
        });
      }
      return value;
    });

    const addDay = (dateIn) => {
      var newDate = new Date(dateIn);
      newDate = new Date(newDate.setDate(newDate.getDate() + 1));
      date.value = newDate;
      todayDate.value = newDate.toLocaleDateString();
    };

    const minusDay = (dateIn) => {
      var newDate = new Date(dateIn);
      newDate = new Date(newDate.setDate(newDate.getDate() - 1));
      date.value = newDate;
      todayDate.value = newDate.toLocaleDateString();
    };

    return {
      plannedRoutes,
      headers,
      totalVolume,
      date,
      dateString,
      addDay,
      minusDay,
      totalStops,
    };
  },
};
</script>

<style>
</style>