<template>
  <div v-if="!supplierDetails">
    <spinner-view />
  </div>
  <div v-else>
    <!-- breadcrumbs -->
    <nav class="breadcrumb is-small" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Suppliers' }">Suppliers</router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'SupplierView', params: { id: supplierId } }"
          >
            {{ supplierDetails.suppliername }}</router-link
          >
        </li>
        <li class="is-active">
          <a href="#" aria-current="page">{{ locationDetails.locationname }}</a>
        </li>
      </ul>
    </nav>

    <div class="container">
      <h1 class="is-size-3 title">
        {{ supplierDetails.suppliername }} - {{ locationDetails.locationname }}
      </h1>

      <!-- Location details and contact information -->
      <!-- <div class="columns">
        <div class="column">
          <div class="box">
            <p class="title is-size-5">Location Details</p>
          </div>
        </div>

        <div class="column">
          <div class="box">
            <p class="title is-size-5">Contact Information</p>
          </div>
        </div>
      </div> -->

      <!-- Settings and Addresses -->
      <div class="columns">
        <!-- Location map -->
        <div
          v-if="
            locationDetails.areaname != 'NIL' &&
            locationDetails.areaname != null
          "
          class="column"
        >
          <div class="box">
            <h2 class="title is-size-5">Location Map</h2>
            <p class="mb-3">
              <b>Zone Name: </b>
              <span v-if="locationDetails.zoneName != null">{{
                locationDetails.zoneName
              }}</span>
              <span v-else>Unlisted</span>
            </p>
            <GoogleMap
              api-key="AIzaSyAhURDudHSVdIaghKlgWaE4BNAw_hdzPSE"
              style="width: 100%; height: 690px"
              :center="center"
              :zoom="15"
            >
              <Marker :options="{ position: center }" />
            </GoogleMap>
          </div>
        </div>

        <!-- Location Settings -->
        <div class="column">
          <!-- Recurring Collection Section -->
          <div class="box">
            <form @submit.prevent="updateReccuring">
              <p class="title is-size-5">Recurring Collection</p>
              <div class="container is-flex">
                <div>
                  <label class="label">Weekday</label>
                  <div class="select control">
                    <select v-model="recurringWeekday" required>
                      <option
                        :value="weekday.value"
                        v-for="weekday in weekdays"
                        :key="weekday.value"
                      >
                        {{ weekday.weekday }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="ml-4">
                  <label class="label">Duration</label>
                  <div class="select control">
                    <select v-model="recurringDuration" required>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                </div>
                <div class="ml-4">
                  <label class="label">Frequency</label>
                  <div class="select control">
                    <select v-model="recurringFrequency" required>
                      <option value="1">Days</option>
                      <option value="7">Weeks</option>
                    </select>
                  </div>
                </div>
                <div class="ml-4">
                  <label class="label">Start Date</label>
                  <div class="control">
                    <VueDatePicker
                      required
                      class="is-rounded"
                      v-model="recurringStartDate"
                      :min-date="new Date()"
                      :disabled-week-days="[5]"
                      :enable-time-picker="false"
                    ></VueDatePicker>
                  </div>
                </div>
              </div>
              <div v-if="locationDetails.nextSchedule">
                <p class="label mt-4">
                  Next Scheulde Date:
                  {{ locationDetails.nextSchedule.toDate().toDateString() }}
                </p>
              </div>
              <button
                class="mt-4 button is-rounded is-success"
                :class="{ 'is-loading': recurringButtonLoading }"
              >
                {{ recurringWeekday != null ? "Update" : "Save" }}
              </button>
            </form>
          </div>

          <!-- Service Window Section -->
          <div class="box">
            <p class="title is-size-5">Service Windows</p>
            <form @submit.prevent="updateServiceWindows">
              <div class="container is-flex">
                <div>
                  <label class="label">Start Time</label>
                  <div class="select control">
                    <select v-model="serviceStartTime" required>
                      <option
                        :value="time.value"
                        v-for="time in timeWindows"
                        :key="time.value"
                      >
                        {{ time.time }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="ml-4">
                  <label class="label">End Time</label>
                  <div class="select control">
                    <select v-model="serviceEndTime" required>
                      <option
                        :value="time.value"
                        v-for="time in timeWindows"
                        :key="time.value"
                      >
                        {{ time.time }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <button
                class="mt-4 button is-rounded is-success"
                :class="{ 'is-loading': serviceTimeButtonLoading }"
              >
                {{ serviceStartTime != null ? "Update" : "Save" }}
              </button>
            </form>
          </div>

          <!-- Containers Sections -->
          <div class="box">
            <div class="is-flex is-justify-content-space-between">
              <p class="title is-size-5">Assets</p>
              <img
                class="mr-2"
                src="@/assets/icons/plus.png"
                alt=""
                style="height: 20px"
                @click="showForm = true"
              />
            </div>
            <div>
              <Vue3EasyDataTable
                :headers="containerHeaders"
                :items="containers"
                header-text-direction="center"
                body-text-direction="center"
                border-cell
              >
                <template #item-operations="{ containerTypeId }">
                  <img
                    class="mr-2"
                    src="@/assets/icons/delete.png"
                    alt=""
                    style="height: 20px"
                    @click="deleteRecord(containerTypeId)"
                  />
                </template>
              </Vue3EasyDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <div v-if="showForm">
      <AddContainer
        :showForm="showForm"
        @close="showForm = false"
        :supplierId="supplierDetails.supplierid"
        :locationId="locationDetails.locationid"
      />
    </div>
  </div>
</template>

<script>
import SpinnerView from "@/components/SpinnerView.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import { GoogleMap, Marker } from "vue3-google-map";
import AddContainer from "@/components/AddContainer.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { useStore } from "vuex";
import { doc, onSnapshot, setDoc, updateDoc } from "@firebase/firestore";
import { db } from "@/firebase/config";
import { ref, watchEffect } from "vue";

export default {
  props: ["supplierId", "locationId"],
  components: {
    SpinnerView,
    Vue3EasyDataTable,
    GoogleMap,
    Marker,
    AddContainer,
    VueDatePicker,
  },
  setup(props) {
    const store = useStore();
    store.commit("setCurrentRoute", "Suppliers");
    const supplierDetails = ref(null);
    const locationDetails = ref(null);
    const containers = ref([]);
    const center = ref(null);
    const showForm = ref(false);
    const recurringWeekday = ref(null);
    const recurringDuration = ref(null);
    const recurringFrequency = ref(null);
    const recurringStartDate = ref(null);
    const recurringButtonLoading = ref(false);
    const dateList = ref([]);
    let fullContainerList = [];

    const serviceStartTime = ref(null);
    const serviceEndTime = ref(null);
    const serviceTimeButtonLoading = ref(false);

    const weekdays = [
      { value: 0, weekday: "Sunday" },
      { value: 1, weekday: "Monday" },
      { value: 2, weekday: "Tuesday" },
      { value: 3, weekday: "Wednesday" },
      { value: 4, weekday: "Thursday" },
      { value: 6, weekday: "Saturday" },
    ];

    const timeWindows = [
      { value: 6, time: "06:00" },
      { value: 7, time: "07:00" },
      { value: 8, time: "08:00" },
      { value: 9, time: "09:00" },
      { value: 10, time: "10:00" },
      { value: 11, time: "11:00" },
      { value: 12, time: "12:00" },
      { value: 13, time: "13:00" },
      { value: 14, time: "14:00" },
      { value: 15, time: "15:00" },
      { value: 16, time: "16:00" },
      { value: 17, time: "17:00" },
      { value: 18, time: "18:00" },
    ];

    // watch(recurringWeekday, () => {
    //   console.log("Weekday selected list updated");
    //   const dates = [];
    //   const today = new Date();
    //   today.setHours(0, 0, 0, 0);
    //   for (let index = 1; index < 30; index++) {
    //     const day = new Date(today);
    //     day.setDate(day.getDate() + index);
    //     const weekday = day.getDay();
    //     if (recurringWeekday.value === weekday) {
    //       console.log("Found a matching week", day);
    //       const timeStampDay = Timestamp.fromDate(day);
    //       dates.push({
    //         date: timeStampDay.toDate().toDateString(),
    //         timestamp: timeStampDay,
    //       });
    //     }
    //   }
    //   dateList.value = dates;
    // });

    let docRef = doc(db, "suppliers", props.supplierId);

    let unsub = onSnapshot(docRef, (doc) => {
      if (doc.data()) {
        console.log(doc.data());
        for (let index = 0; index < doc.data().locations.length; index++) {
          const location = doc.data().locations[index];
          if (location.locationid === parseInt(props.locationId)) {
            console.log("Match found", location.locationid);
            if (location.areaname) {
              console.log("Updating URL");
              let url = store.state.apiUrl + "/getLatLng/" + location.areaname;
              fetch(url)
                .then((res) => res.json())
                .then((data) => (center.value = data));
            }
            if (location.frequency) {
              console.log("Has frequency data");
              recurringDuration.value = location.duration;
              recurringFrequency.value = location.frequency;
              recurringWeekday.value = location.recurringWeekday;
              recurringStartDate.value = location.recurringStartDate;
            }
            locationDetails.value = { ...location };
          }
        }
        if (doc.data().containers) {
          let conatainerList = [];
          let _fullContainerList = [];
          for (let index = 0; index < doc.data().containers.length; index++) {
            const container = doc.data().containers[index];
            if (
              doc.data().containers[index].locationId ===
              parseInt(props.locationId)
            ) {
              conatainerList.push(container);
            }
            _fullContainerList.push(container);
          }
          containers.value = conatainerList;
          fullContainerList = _fullContainerList;
        }
        supplierDetails.value = { ...doc.data() };
      }
    });

    watchEffect((onInvalidate) => {
      onInvalidate(() => {
        unsub();
      });
    });

    const updateReccuring = async () => {
      recurringButtonLoading.value = true;

      let locationIndex = supplierDetails.value.locations.findIndex(
        (location) => location.locationid == parseInt(props.locationId)
      );
      console.log(
        "Location before recurring",
        supplierDetails.value.locations[locationIndex]
      );

      supplierDetails.value.locations[locationIndex] = {
        ...supplierDetails.value.locations[locationIndex],
        duration: parseInt(recurringDuration.value),
        frequency: parseInt(recurringFrequency.value),
        lastSchedule: null,
        nextSchedule: recurringStartDate.value,
        recurringStartDate: recurringStartDate.value,
        recurringWeekday: recurringWeekday.value,
      };

      console.log(
        "Location after recurring",
        supplierDetails.value.locations[locationIndex]
      );

      await setDoc(docRef, supplierDetails.value, { merge: true });

      recurringButtonLoading.value = false;
    };

    const updateServiceWindows = async () => {
      serviceTimeButtonLoading.value = true;

      let locationIndex = supplierDetails.value.locations.findIndex(
        (location) => location.locationid == parseInt(props.locationId)
      );
      console.log(
        "Location before service window",
        supplierDetails.value.locations[locationIndex]
      );

      supplierDetails.value.locations[locationIndex] = {
        ...supplierDetails.value.locations[locationIndex],
        serviceWindowStartTime: serviceStartTime.value,
        serviceWindowEndTime: serviceEndTime.value,
      };

      console.log(
        "Location after service window",
        supplierDetails.value.locations[locationIndex]
      );

      await setDoc(docRef, supplierDetails.value, { merge: true });

      serviceTimeButtonLoading.value = false;
    };

    const deleteRecord = async (containerType) => {
      console.log(containerType, props.locationId);
      var newContainerList = fullContainerList.filter((container) => {
        return !(
          container.containerTypeId === containerType &&
          container.locationId === parseInt(props.locationId)
        );
      });
      console.log(newContainerList);
      await updateDoc(
        docRef,
        { containers: newContainerList },
        { merge: true }
      );
    };

    const containerHeaders = [
      { text: "Conatiner Type", value: "containerType" },
      { text: "Volume", value: "maxVolume" },
      { text: "Quantity", value: "quantity" },
      { text: "Operations", value: "operations" },
    ];

    return {
      supplierDetails,
      locationDetails,
      center,
      showForm,
      recurringWeekday,
      recurringDuration,
      recurringFrequency,
      recurringStartDate,
      recurringButtonLoading,
      dateList,
      weekdays,
      updateReccuring,
      containerHeaders,
      containers,
      timeWindows,
      serviceStartTime,
      serviceEndTime,
      serviceTimeButtonLoading,
      updateServiceWindows,
      deleteRecord,
    };
  },
};
</script>
