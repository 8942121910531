<template>
    <div class="container">
        <div class="is-flex is-justify-content-space-between px-3">
            <h1 class="is-size-3 title">Sites</h1>
            </div>
    </div>
</template>

<script>

export default {
    setup() {
       

        }
}
</script>

<style>

</style>