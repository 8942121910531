<template>
  <div class="container">
    <div class="container" v-if="isLoading">
      <SpinnerView />
    </div>

    <div class="container" v-else>
      <h1 class="is-size-3 title">Zone Planning</h1>
      <div class="container is-flex is-justify-content-space-between"></div>
      <Vue3EasyDataTable
        :headers="headers"
        :items="data"
        header-text-direction="center"
        body-text-direction="center"
        border-cell
      >
        <template
          v-for="day in [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ]"
          :key="day"
          #[`item-${day}`]="{ zoneOperationalDays, zoneId }"
        >
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="zoneOperationalDays[day]"
              @change="
                updateFirebaseDocument(zoneId, day, zoneOperationalDays[day])
              "
            />
          </label>
        </template>
      </Vue3EasyDataTable>
    </div>
  </div>
</template>
  
  <script>
import { ref } from "vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import SpinnerView from "@/components/SpinnerView.vue";
import { useStore } from "vuex";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase/config";

export default {
  setup() {
    const headers = [
      { text: "Zone", value: "zoneName" },
      { text: "Sunday", value: "Sunday" },
      { text: "Monday", value: "Monday" },
      { text: "Tuesday", value: "Tuesday" },
      { text: "Wednesday", value: "Wednesday" },
      { text: "Thursday", value: "Thursday" },
      { text: "Friday", value: "Friday" },
      { text: "Saturday", value: "Saturday" },
    ];
    const store = useStore();
    const data = ref([]);
    const isLoading = ref(true);

    // Get data from API end point /getZonePlannerData/{siteId} and assign to data
    const getZonePlannerData = async () => {
      try {
        const apiUrl =
          store.state.apiUrl + "/getZonePlannerData/" + store.state.currentSite;
        const response = await fetch(apiUrl);
        const _data = await response.json();
        data.value = _data;
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const updateFirebaseDocument = async (id, weekday, value) => {
      const zoneDoc = doc(db, "zoneOperationalDays", id);
      try {
        await updateDoc(zoneDoc, {
          [weekday]: value,
        });
      } catch (error) {
        console.error("Failed to update document:", error);
      } finally {
        await getZonePlannerData();
      }
    };

    getZonePlannerData();

    return {
      headers,
      data,
      isLoading,
      updateFirebaseDocument,
    };
  },
  components: {
    Vue3EasyDataTable,
    SpinnerView,
  },
};
</script>
  
  <style>
</style>