<template>
  <footer class="footer footer-background-color">
    <div class="content has-text-centered">
      <p>
        <strong>Neutral Fuels OMS</strong> developed by
        <a href="https://neutralfuels.com">Neutral Fuels LLC</a>.
      </p>
      <p>
        The source code is licensed
        <a href="#">Neutral Fuels Holdings Ltd</a>.
      </p>
      <p>ⓒ 2022 Neutral Fuels LLC</p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>