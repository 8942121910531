<template>
  <nav class="navbar is-white has-shadow">
    <!-- Logo and title -->
    <div class="navbar-brand">
      <router-link class="navbar-item" :to="{ name: 'Home' }">
        <img
          src="@/assets/globe.png"
          style="max-height: 70px"
          class="py-2 px-2"
        />
        <p class="title">Neutral Fuels OMS</p>
      </router-link>

      <!-- Alt view for mobiles -->
      <a class="navbar-burger">
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>

    <!-- for authenticated users -->
    <div class="navbar-menu" v-if="user">
      <div class="navbar-end">
        <div class="field mr-5 mt-4">
          <label class="label"></label>
          <div class="select">
            <select @change="onChange($event)" v-model="userSite">
              <option
                :value="site.siteId"
                v-for="site in availableSites"
                :key="site.siteId"
              >
                {{ site.siteName }}
              </option>
            </select>
          </div>
        </div>

        <p class="navbar-item">
          Currently logged in as:
          <span class="ml-1 has-text-weight-bold"> {{ user.displayName }}</span>
        </p>

        <p class="navbar-item">{{ versionNumber }}</p>
        <!-- <router-link :to="{name: 'MyAccount'}" class="navbar-item">My Account</router-link> -->

        <p class="navbar-item">
          <button
            class="navbar-item button is-rounded is-danger"
            @click="handleClick"
          >
            Logout
          </button>
        </p>
      </div>
    </div>

    <!-- for unauthenticated users -->
    <div class="navbar-menu" v-if="!user">
      <div class="navbar-end">
        <p class="navbar-item">{{ versionNumber }}</p>
        <router-link :to="{ name: 'Signup' }" class="navbar-item"
          >Sign Up</router-link
        >
        <router-link :to="{ name: 'Login' }" class="navbar-item"
          >Login</router-link
        >
      </div>
    </div>
  </nav>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase/config";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();

    const handleClick = async () => {
      await store.dispatch("logout");
      router.push({ name: "Login" });
    };

    const onChange = async (siteId) => {
      console.log("Site changed", siteId.target.value);
      console.log(store.state.user.uid);

      const userRef = doc(db, "users", store.state.user.uid);

      // Set the "capital" field of the city 'DC'
      await updateDoc(userRef, {
        defaulltSite: siteId.target.value,
      });

      store.commit("setCurrentSite", siteId.target.value);

      window.location.reload();
    };

    return {
      user: computed(() => store.state.user),
      versionNumber: computed(() => store.state.versionNumber),
      userSite: computed(() => store.state.currentSite),
      availableSites: computed(() => store.state.userSites),
      handleClick,
      onChange,
    };
  },
};
</script>

<style>
</style>