<template>
    <div class="modal" :class="{'is-active': showForm}">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <div class="is-flex is-justify-content-space-between">
                    <p class="modal-card-title">Assign Container</p>
                </div>
            </header>
            <section class="modal-card-body">
                <form @submit.prevent>
                    <!-- select container type -->
                    <div class="field">
                        <label class="label">Container Type</label>
                        <div class="select control">
                            <select v-model="selectedContainer">
                                <option :value="container.id" v-for="container in documents" :key="container.id">{{ container.containerType }}</option>
                            </select>
                        </div>
                    </div>

                    <div v-if="selectedContainer" class="field">
                        <div class="label">Max Volume</div>
                        <p>{{ maxVolume * quantity }}</p>
                    </div>

                    <div v-if="selectedContainer" class="field">
                        <div class="label">Quantity</div>
                        <div class="is-flex is-justify-content-space-between">
                            <p>{{ quantity }}</p>
                            <div>
                                <button @click="quantity -= 1" class="button is-rounded is-danger"> <p class="is-size-3">-</p></button>
                                <button @click="quantity += 1" class="mx-2 button is-rounded is-primary"><p class="is-size-3">+</p></button>
                            </div>
                            
                        </div>
                        
                    </div>
                </form>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-rounded is-success" :class="{ 'is-loading': isPending }" @click="handleClick">Add</button>
                <button class="button is-rounded" @click="closeModal">Cancel</button>
            </footer>
        </div>
    </div>
</template>

<script>
import getCollection from '@/composables/getCollection'
import { db } from '@/firebase/config'
import { doc, onSnapshot, updateDoc } from '@firebase/firestore'
import { ref, watch, watchEffect } from 'vue'

export default {
    props: [ 'showForm', 'supplierId', 'locationId' ],
    setup(props, context) {
        const quantity = ref(0)
        const selectedContainer = ref(null)
        const selectedContainerObj = ref(null)
        const maxVolume = ref(0)
        const isPending = ref(false)
        const containers = ref([])

        const { documents } = getCollection('containers')

        let docRef = doc(db, 'suppliers', props.supplierId)

        let unsub = onSnapshot(docRef, (doc) => {
            if (doc.data()) {
                if (doc.data().containers) {
                    containers.value = doc.data().containers
                }
            }
        })

        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub())
        })

        watch(selectedContainer, () => {
            documents.value.forEach(container => {
                if (container.id == selectedContainer.value) {
                    maxVolume.value = container.maxVolume
                    selectedContainerObj.value = container
                    quantity.value = 0
                }
            });
        })

        const handleClick = async () => {
            isPending.value = true
            const containerData = {
                'locationId': props.locationId,
                'containerType': selectedContainerObj.value.containerType,
                'containerTypeId': selectedContainerObj.value.id,
                'maxVolume': selectedContainerObj.value.maxVolume,
                'quantity': quantity.value
            }
            containers.value.push(containerData)
            const data = {'containers' : containers.value}

            console.log(data)

            let supplierRef = doc(db, 'suppliers', props.supplierId)

            await updateDoc(supplierRef, data, {merge: true})
            isPending.value = false

            closeModal()

            // console.log(data)
        }

        // Close modal
        const closeModal = () => {
            context.emit('close')
        }

        return { closeModal, documents, quantity, selectedContainer, maxVolume, handleClick, isPending }
    }
}
</script>

<style>

</style>