
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, serverTimestamp } from 'firebase/firestore'

// 
const firebaseOmsConfig = {
    apiKey: "AIzaSyANRvhh6rdgeXI_ki7lUXciTRdf4LYqiOg",
    authDomain: "nf-oms.firebaseapp.com",
    projectId: "nf-oms",
    storageBucket: "nf-oms.appspot.com",
    messagingSenderId: "303598619794",
    appId: "1:303598619794:web:7c33f3863e95323c87aa4b",
    measurementId: "G-WESXL9Z50Q"
};

// Test ENV FirebaseConfig
const testEnvFirebaseConfig = {
    apiKey: "AIzaSyDdosRvv4sZmIWchpeSstiedahfGI8MPak",
    authDomain: "neutral-fuels-oms-testenv.firebaseapp.com",
    projectId: "neutral-fuels-oms-testenv",
    storageBucket: "neutral-fuels-oms-testenv.appspot.com",
    messagingSenderId: "1052546856140",
    appId: "1:1052546856140:web:254b2b00477b50dd0abe69"
};

// init firebase OMS Backend
initializeApp(firebaseOmsConfig)

// init services
const auth = getAuth()
const db = getFirestore()
const timestamp = serverTimestamp

export { db, auth, timestamp }