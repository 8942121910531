<template>
  <div v-if="!document">
    <SpinnerView />
  </div>
  <div v-else>
    <nav class="breadcrumb is-small" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Orders' }">Orders</router-link>
        </li>
        <li class="is-active">
          <a href="#" aria-current="page">{{ document.supplierName }}</a>
        </li>
      </ul>
    </nav>

    <!-- Order Details and Status -->
    <div v-if="document" class="columns">
      <!-- Order Details -->
      <div class="column box mx-3">
        <h2 class="is-size-4">Order Details</h2>

        <div class="mt-5">
          <!-- Supplier and Location Details -->
          <p><b>Order ID: </b> {{ id }}</p>

          <p>
            <b>Supplier Name: </b>
            <router-link
              :to="{
                name: 'SupplierView',
                params: { id: document.supplierId },
              }"
            >
              {{ document.supplierName }}
            </router-link>
          </p>
          <p v-if="document.locationName">
            <b>Location Name: </b> {{ document.locationName }}
          </p>
          <p>
            <b>Created By: </b> {{ document.createdBy }} via
            {{ document.requestedBy }}
          </p>

          <!-- Order Volume -->
          <div class="mt-5">
            <p>
              <b>Expected Volume: </b>
              {{
                document.estimatedVolume
                  ? document.estimatedVolume + " (ltrs)"
                  : "0 (ltrs)"
              }}
            </p>
            <p>
              <b>Actual Volume: </b>
              {{
                document.actualVolume
                  ? document.actualVolume + " (ltrs)"
                  : "0 (ltrs)"
              }}
            </p>
          </div>

          <!-- Scheduling Details -->
          <div class="mt-5">
            <p>
              <b>Requested For: </b>
              {{ document.requestedDate.toDate().toDateString() }}
            </p>
            <span
              ><b>Planned For: </b>
              <select
                v-if="plannedFor && document.status == 'PLANNED' && editOrder"
                v-model="plannedFor"
                required
              >
                <option
                  :value="date.timestamp"
                  v-for="date in dateList"
                  :key="date.date"
                >
                  {{ date.date }}
                </option>
              </select>

              <span v-else>{{
                plannedFor
                  ? document.scheduledDate.toDate().toDateString()
                  : "TBC"
              }}</span>
            </span>
            <p v-if="document.routeId">
              <b>Route ID: </b>
              <router-link
                :to="{ name: 'RouteView', params: { id: document.routeId } }"
              >
                {{ document.routeId }}
              </router-link>
            </p>
          </div>

          <!-- Sign off details -->
          <div v-if="document.status == 'COMPLETED'" class="mt-5">
            <span v-if="document.signedOffBy"
              ><p><b>Signed off by: </b> {{ document.signedOffBy }}</p></span
            >

            <span v-if="imageData"
              ><b>Signature: </b>
              <img
                v-bind:src="'data:image/png;base64,' + imageData"
                alt="Image"
                height="20"
                width="200"
              />
            </span>
          </div>
        </div>

        <div class="mt-5">
          <table class="table is-bordered is-fullwidth">
            <thead>
              <tr>
                <th>Requested At</th>
                <th>Accepted At</th>
                <th>Scheduled At</th>
                <th>Collected At</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  {{
                    document.createdAt
                      ? document.createdAt.toDate().toLocaleString()
                      : " Pending"
                  }}
                </td>
                <td>
                  {{
                    document.plannedAt
                      ? document.plannedAt.toDate().toLocaleString()
                      : "Pending"
                  }}
                </td>
                <td>
                  {{
                    document.scheduledAt
                      ? new Date(document.scheduledAt).toLocaleString()
                      : "Pending"
                  }}
                </td>
                <td>
                  {{
                    document.collectedAt
                      ? document.collectedAt.toDate().toLocaleString()
                      : "Pending"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="column mx-3">
        <!-- Status -->
        <div class="box" v-if="!editOrder">
          <div
            class="container is-flex is-justify-content-space-between is-align-items-center"
          >
            <h1 class="is-size-4">
              Status:
              <span
                v-if="document.status == 'FAILED'"
                class="is-size-5 tag is-rounded"
                :class="{ 'is-danger': document.status == 'FAILED' }"
                >{{ document.status }}</span
              >

              <span
                v-if="document.status == 'REQUESTED'"
                class="is-size-5 tag is-rounded"
                >{{ document.status }}</span
              >
              <span
                v-if="document.status == 'PLANNED'"
                class="is-size-5 tag is-rounded"
                :class="{ 'is-warning': document.status == 'PLANNED' }"
                >{{ document.status }}</span
              >
              <span
                v-if="document.status == 'SCHEDULED'"
                class="is-size-5 tag is-rounded"
                :class="{ 'is-success': document.status == 'SCHEDULED' }"
                >{{ document.status }}</span
              >
              <span
                v-if="document.status == 'COMPLETED'"
                class="is-size-5 tag is-rounded"
                :class="{ 'is-dark': document.status == 'COMPLETED' }"
                >{{ document.status }}</span
              >
            </h1>
            <div>
              <button
                @click="editOrder = !editOrder"
                class="button is-rounded"
                v-if="
                  document.status == 'PLANNED' ||
                  document.status == 'SCHEDULED' ||
                  document.status == 'FAILED'
                "
              >
                <img
                  src="@/assets/icons/pencil.png"
                  alt=""
                  style="height: 20px"
                />
              </button>
            </div>
          </div>
          <div v-if="document.notes"><b>Notes:</b> {{ document.notes }}</div>
        </div>

        <!-- Edit order section -->
        <div class="box" v-else>
          <div
            class="container is-flex is-justify-content-space-between is-align-items-center"
          >
            <h1 class="is-size-4">Edit Order</h1>
            <button
              @click="editOrder = !editOrder"
              class="button is-rounded"
              v-if="
                document.status == 'PLANNED' ||
                document.status == 'SCHEDULED' ||
                document.status == 'FAILED'
              "
            >
              <img
                class="mr-2"
                src="@/assets/icons/delete.png"
                alt=""
                style="height: 20px"
                @click="editOrder = !editOrder"
              />
            </button>
          </div>

          <div class="container is-flex is-flex-direction-column">
            <form @submit.prevent="">
              <div class="field is-flex is-align-items-center">
                <label class="label">Status: </label>
                <div class="select control ml-5">
                  <select required v-model="editOrderStatus">
                    <option value="SCHEDULED">SCHEDULED</option>
                    <option value="FAILED">FAILED</option>
                    <option value="COMPLETED">COMPLETED</option>
                  </select>
                </div>
              </div>

              <div
                class="field is-flex is-align-items-center"
                v-if="editOrderStatus == 'COMPLETED'"
              >
                <label class="label">Actual Volume: </label>
                <div class="control ml-5">
                  <input
                    class="input is-normal"
                    v-model="editOrderActualVolume"
                    required
                  />
                </div>
              </div>

              <div class="field is-flex is-align-items-center">
                <label class="label">Notes: </label>
                <div class="control ml-5">
                  <!-- <input
                    type="x liters"
                    class="input is-normal"
                    v-model="editOrderNotes"
                    required
                  /> -->
                  <textarea
                    class="input is-normal textarea"
                    placeholder="Provide a valid reasoiong for updating the order manually"
                    v-model="editOrderNotes"
                    required
                  ></textarea>
                </div>
              </div>

              <button
                class="button is-rounded is-normal is-info"
                @click="updateOrder"
              >
                Update
              </button>
            </form>
          </div>
        </div>

        <!-- Location Map -->
        <div class="box">
          <h2 class="is-size-4">Location Map</h2>
          <GoogleMap
            api-key="AIzaSyAhURDudHSVdIaghKlgWaE4BNAw_hdzPSE"
            style="width: 100%; height: 300px"
            :center="center"
            :zoom="15"
          >
            <Marker :options="{ position: center }" />
          </GoogleMap>
        </div>
      </div>
    </div>

    <!-- Past orders -->
    <div v-if="pastOrders.length" class="box mr-3">
      <h2 class="is-size-4">Past Orders</h2>
      <div class="mt-5">
        <EasyDataTable
          :headers="pastOrderHeaders"
          :items="pastOrders"
          header-text-direction="center"
          body-text-direction="center"
          border-cell
        >
          <template #item-id="{ id }">
            <!-- TODO: Update the URL to point to the actual server -->
            <a
              :href="
                'https://nf-test-oms.neutralfuels.net/dashboard/operations/order-view/' +
                id +
                '/' +
                'None'
              "
            >
              {{ id }}</a
            >
            <!-- <a @click="routeTo(id)">{{ id }}</a> -->
          </template>

          <template #item-createdAt="{ createdAt }">
            {{ createdAt.toDate().toLocaleString() }}
          </template>

          <template #item-collectedAt="{ collectedAt }">
            {{
              collectedAt ? collectedAt.toDate().toLocaleString() : "Pending"
            }}
          </template>

          <template #item-status="{ status }">
            <span
              v-if="status == 'FAILED'"
              class="tag is-rounded"
              :class="{ 'is-danger': status == 'FAILED' }"
              >{{ status }}</span
            >
            <span v-if="status == 'REQUESTED'" class="tag is-rounded">{{
              status
            }}</span>
            <span
              v-if="status == 'PLANNED'"
              class="tag is-rounded"
              :class="{ 'is-warning': status == 'PLANNED' }"
              >{{ status }}</span
            >
            <span
              v-if="status == 'SCHEDULED'"
              class="tag is-rounded"
              :class="{ 'is-success': status == 'SCHEDULED' }"
              >{{ status }}</span
            >
            <span
              v-if="status == 'COMPLETED'"
              class="tag is-rounded"
              :class="{ 'is-dark': status == 'COMPLETED' }"
              >{{ status }}</span
            >
          </template>
        </EasyDataTable>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerView from "@/components/SpinnerView.vue";
import { GoogleMap, Marker } from "vue3-google-map";
import Vue3EasyDataTable from "vue3-easy-data-table";
import { useStore } from "vuex";
import { ref, watchEffect, computed, watch } from "vue";
import {
  collection,
  doc,
  onSnapshot,
  query,
  Timestamp,
  updateDoc,
  where,
  setDoc,
} from "@firebase/firestore";
import { db } from "@/firebase/config";
import router from "@/router";
import { Buffer } from "buffer";

export default {
  props: ["id", "supplierId", "locationId"],
  setup(props) {
    const store = useStore();
    const document = ref(null);
    const center = ref(null);
    const pastOrders = ref([]);
    const plannedFor = ref(null);
    const imageData = ref(null);
    const editOrder = ref(false);

    const editOrderStatus = ref(null);
    const editOrderActualVolume = ref(null);
    const editOrderNotes = ref(null);

    const dateList = computed(() => {
      const dates = [];
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const timeStampToday = Timestamp.fromDate(today);
      dates.push({
        date: timeStampToday.toDate().toDateString(),
        timestamp: timeStampToday,
      });
      for (let index = 1; index < 7; index++) {
        const day = new Date(today);
        day.setDate(day.getDate() + index);
        const timeStampDay = Timestamp.fromDate(day);
        dates.push({
          date: timeStampDay.toDate().toDateString(),
          timestamp: timeStampDay,
        });
      }
      return dates;
    });

    let docRef = doc(db, "collectionRequests", props.id);
    let colRef = collection(db, "collectionRequests");

    let unsub = onSnapshot(docRef, (doc) => {
      if (doc.data()) {
        let url = store.state.apiUrl + "/getLatLng/" + doc.data().geotag;
        fetch(url)
          .then((res) => res.json())
          .then((data) => (center.value = data));
        document.value = { ...doc.data() };
        // const fileReader = new FileReader()
        // fileReader.readAsArrayBuffer(new Blob(doc.data().signedOffSignature))
        // fileReader.onloadend = () => {
        //   const _imageData = fileReader.result
        //   // You can now use the `imageData` variable as a string containing the binary data of the image
        //   console.log(_imageData)
        //   imageData.value = _imageData
        // }
        if (doc.data().signedOffSignature) {
          const base64 = Buffer.from(doc.data().signedOffSignature).toString(
            "base64"
          );
          imageData.value = base64;
        }

        if (doc.data().notes) {
          editOrderNotes.value = doc.data().notes;
        }

        editOrderStatus.value = doc.data().status;

        // console.log(base64);

        plannedFor.value = doc.data().scheduledDate;
      }
    });
    if (props.supplierId != "") {
      colRef = query(colRef, where("supplierId", "==", props.supplierId));
    }

    let unsub2 = onSnapshot(colRef, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        // if (document.value) {
        // if (doc.data().supplierId == document.value.supplierId) {
        results.push({ ...doc.data(), id: doc.id });
        // }
        // }
      });

      pastOrders.value = results;
    });

    const pastOrderHeaders = [
      { text: "Order ID", value: "id" },
      { text: "Requested Date", value: "createdAt" },
      { text: "Collected Date", value: "collectedAt" },
      { text: "Volume", value: "actualVolume" },
      { text: "Status", value: "status" },
    ];

    const routeTo = (id) => {
      router.push({ name: "OrderView", params: { id: id } });
    };

    watch(plannedFor, async () => {
      if (
        plannedFor.value.toDate().getTime() !==
        document.value.scheduledDate.toDate().getTime()
      ) {
        await updateDoc(docRef, {
          modifiedAt: Timestamp.now(),
          scheduledDate: plannedFor.value,
        });
      }
    });

    watchEffect((onInvalidate) => {
      onInvalidate(() => {
        unsub();
        unsub2();
      });
    });

    const updateOrder = async () => {
      if (editOrderActualVolume.value) {
        await updateDoc(
          docRef,
          {
            status: editOrderStatus.value,
            modifiedAt: Timestamp.now(),
            actualVolume: editOrderActualVolume.value,
            notes: editOrderNotes.value,
            collectedAt: Timestamp.now(),
          },
          { merge: true }
        );
      } else {
        await updateDoc(
          docRef,
          {
            modifiedAt: Timestamp.now(),
            status: editOrderStatus.value,
            notes: editOrderNotes.value,
          },
          { merge: true }
        );
      }

      editOrder.value = !editOrder.value;
    };

    return {
      document,
      center,
      pastOrderHeaders,
      pastOrders,
      routeTo,
      plannedFor,
      dateList,
      imageData,
      editOrder,
      editOrderNotes,
      editOrderActualVolume,
      editOrderStatus,
      updateOrder,
    };
  },
  components: { GoogleMap, Marker, Vue3EasyDataTable, SpinnerView },
};
</script>