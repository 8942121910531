<template>
  <div class="container">
    <div class="container" v-if="isLoading">
      <spinner-view />
    </div>
    <div class="container" v-else>
      <div class="is-flex is-justify-content-space-between">
        <h1 class="is-size-3 title">Inventory List</h1>
        <button
          class="button is-rounded is-primary"
          :class="{ 'is-loading': isExporting }"
          @click="exportInventoryToCSV"
          :disabled="isExporting"
        >
          Export Inventory (.csv)
        </button>
      </div>

      <div class="card-container mt-5">
        <div
          v-for="(count, containerType) in totals_data['container_totals']"
          :key="containerType"
          class="card"
        >
          <h1 class="is-size-5">{{ containerType }}</h1>
          <p class="is-size-4">{{ count }}</p>
        </div>
      </div>

      <div class="container is-flex mt-5">
        <div class="field mr-5">
          <label class="label">Search Item</label>
          <div class="select">
            <select v-model="searchField">
              <option value="SupplierName">Supplier Name</option>
              <option value="LocationName">Location Name</option>
              <option value="ContainerType">Container Type</option>
            </select>
          </div>
        </div>

        <div class="field mr-5">
          <label class="label">Search For</label>
          <input class="input" type="text" v-model="searchValue" />
        </div>
      </div>

      <div class="mt-5">
        <EasyDataTable
          :headers="headers"
          :items="container_data"
          :search-field="searchField"
          :search-value="searchValue"
          header-text-direction="center"
          body-text-direction="center"
          table-class-name="customize-table"
          border-cell
          show-index
        >
          <template #item-SupplierName="{ SupplierName, SupplierID }">
            <router-link
              :to="{ name: 'SupplierView', params: { id: SupplierID } }"
            >
              {{ SupplierName }}
            </router-link>
          </template>

          <template
            #item-LocationName="{ LocationName, SupplierID, LocationID }"
          >
            <router-link
              :to="{
                name: 'LocationView',
                params: {
                  locationId: LocationID,
                  supplierId: SupplierID,
                },
              }"
            >
              {{ LocationName }}
            </router-link>
          </template>
        </EasyDataTable>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerView from "@/components/SpinnerView.vue";
import { ref } from "vue";
import { useStore } from "vuex";
export default {
  components: { SpinnerView },
  setup() {
    const store = useStore();
    const container_data = ref([]);
    const totals_data = ref([]);
    const isLoading = ref(false);
    const searchField = ref("SupplierName");
    const searchValue = ref("");
    const isExporting = ref(false);

    const getInvetoryData = async () => {
      isLoading.value = true;
      const apiUrl =
        store.state.apiUrl +
        "/getInventoryListBySite/" +
        store.state.currentSite;
      try {
        const response = await fetch(apiUrl);

        if (response.ok) {
          const result = await response.json();
          container_data.value = result["container_data"];
          totals_data.value = result["totals_data"];
        }
      } catch (error) {
        console.debug(error);
      } finally {
        isLoading.value = false;
      }
    };

    getInvetoryData();

    const triggerDownloadCSV = (csvContent, filename) => {
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", filename);

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    const convertToCSV = (data) => {
      const headers = [
        "Supplier Name",
        "Location Name",
        "Container Type",
        "No of Containers",
      ];

      const csvRows = [];

      // Add headers
      csvRows.push(headers.join(","));
      // //console.log(data);

      // Add data
      for (const item of data) {
        const row = [
          item.SupplierName,
          item.LocationName,
          item.ContainerType,
          item.NoOfContainers,
        ];
        csvRows.push(row.join(","));
      }

      return csvRows.join("\n");
    };

    const exportInventoryToCSV = () => {
      try {
        isExporting.value = true;
        // Step 2: Convert data into CSV format
        const csvContent = convertToCSV(container_data.value);

        // Step 3: Trigger download
        triggerDownloadCSV(csvContent, "inventory.csv");
      } catch (error) {
        console.debug(error);
      } finally {
        isExporting.value = false;
      }
    };

    const headers = [
      { text: "Supplier Name", value: "SupplierName", sortable: true },
      { text: "Location Name", value: "LocationName" },
      { text: "Container Type", value: "ContainerType", sortable: true },
      { text: "Quantity", value: "NoOfContainers" },
    ];

    return {
      isLoading,
      container_data,
      totals_data,
      headers,
      exportInventoryToCSV,
      searchField,
      searchValue,
      isExporting,
    };
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  justify-content: space-around;
  gap: 16px; /* Adjust space between cards */
}

.card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd; /* Adjust border styling */
  border-radius: 10px; /* Adjust border-radius */
  background-color: #ffffff; /* Adjust background-color */
  text-align: center;
}

/* Optional: Add some responsive styling */
@media (max-width: 600px) {
  .card-container {
    flex-direction: column;
  }
}
</style>
