<template>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="is-flex is-justify-content-space-between">
          <p class="modal-card-title">Schedule Collections</p>
        </div>
      </header>

      <section class="modal-card-body">
        <div class="mb-5" v-if="collectionRequests.length == 1">
          <button
            class="button is-success"
            @click="allowInsertRoute = !allowInsertRoute"
            :disabled="!allRoutes.length"
          >
            {{ allowInsertRoute ? "Create new route" : "Add to planned route" }}
          </button>
        </div>

        <form v-if="!allowInsertRoute">
          <div class="field">
            <div class="control">
              <input
                class="input is-normal"
                placeholder="Driver Name"
                v-model="driverName"
              />
            </div>
          </div>

          <!-- DUBAI TRUCKS  -->
          <div
            class="field"
            v-if="userSite == 'fa669bd0-42f8-4edf-8847-9acd191759f1'"
          >
            <label class="label">Truck Number</label>
            <div class="select control">
              <select v-model="truckNumber">
                <option value="5545">5545</option>
                <option value="66450">66450</option>
              </select>
            </div>
          </div>

          <!-- ABU DHABI TRUCKS -->
          <div
            class="field"
            v-if="userSite == 'a8b87580-1745-408a-a9fb-0d27e4f7ce2d'"
          >
            <label class="label">Truck Number</label>
            <div class="select control">
              <select v-model="truckNumber">
                <option value="14927">14927</option>
                <option value="22301">22301</option>
              </select>
            </div>
          </div>

          <div class="field">
            <label class="label">Route Type</label>
            <div class="select control">
              <select v-model="routeType">
                <option value="UCO">UCO</option>
                <option value="B100">B100</option>
                <option value="B20">B20</option>
                <option value="B5">B5</option>
              </select>
            </div>
          </div>

          <!-- Dubai Start Points -->
          <div
            class="field"
            v-if="userSite == 'fa669bd0-42f8-4edf-8847-9acd191759f1'"
          >
            <label class="label">Route Start</label>
            <div class="select control">
              <select v-model="routeStart">
                <option value="DXB_NF_SITE">Dubai Factory</option>
                <option value="DXB_NF_ACCOM">Dubai Accomodation</option>
              </select>
            </div>
          </div>

          <!-- Abu Dhabi Start Points -->
          <div
            class="field"
            v-if="userSite == 'a8b87580-1745-408a-a9fb-0d27e4f7ce2d'"
          >
            <label class="label">Route Start</label>
            <div class="select control">
              <select v-model="routeStart">
                <option value="AUH_NF_SITE">Abu Dhabi Factory</option>
              </select>
            </div>
          </div>

          <div class="field">
            <label class="label">Planned Date</label>
            <div class="select control">
              <select v-model="plannedDateIso">
                <option
                  :value="date.timestamp"
                  v-for="date in dateList"
                  :key="date.date"
                >
                  {{ date.date }}
                </option>
              </select>
            </div>
          </div>
        </form>

        <form v-else>
          <div class="field">
            <label class="label">Planned Routes</label>
            <div class="select control">
              <select v-model="selectedRouteId">
                <option
                  :value="route.id"
                  v-for="route in allRoutes"
                  :key="route.id"
                >
                  {{
                    route.nfRouteType +
                    " - " +
                    route.route_id +
                    " - " +
                    route.member_first_name +
                    " - " +
                    route.schduledDate +
                    " - " +
                    route.nfTruckNumber
                  }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </section>

      <footer class="modal-card-foot">
        <button
          class="button is-success"
          :class="{ 'is-loading': isPending }"
          @click="handleClick"
        >
          {{ allowInsertRoute ? "Add" : "Create" }}
        </button>
        <button class="button" @click="closeModal">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase/config";
import {
  collection,
  onSnapshot,
  where,
  query,
  Timestamp,
} from "@firebase/firestore";
import { ref, computed, watchEffect } from "vue";
import { useStore } from "vuex";

export default {
  props: ["showForm", "collectionRequests"],
  setup(props, context) {
    const store = useStore();

    const isPending = ref(false);
    const driverName = ref("");
    const truckNumber = ref("");
    const routeType = ref("");
    const routeStart = ref("");
    const plannedDateIso = ref("");
    const addresses = ref([]);
    const singleAddress = ref({});
    const allRoutes = ref([]);

    const allowInsertRoute = ref(false);
    const selectedRouteId = ref("");

    const date = ref(new Date());
    const todayDate = ref(date.value.toLocaleDateString());

    let colRef = collection(db, "plannedRoutes");

    const unsub = onSnapshot(colRef, (snapshot) => {
      let results = [];
      const docs = snapshot.docs;

      for (let i = 0; i < docs.length; i++) {
        const doc = docs[i];
        let scheduleDate;

        // Only compute the if condition
        if (doc.data().schduledDate.includes("-")) {
          // If the date is in "2023-09-16T20:00:00.000Z" format
          scheduleDate = new Date(doc.data().schduledDate);

          // Adjusting for timezone offset to keep the date consistent
          scheduleDate.setMinutes(
            scheduleDate.getMinutes() + scheduleDate.getTimezoneOffset()
          );
        } else {
          // Skip to the next iteration of the loop
          continue;
        }

        let today = new Date();
        today.setHours(0, 0, 0, 0); // Set to the start of the day.

        if (
          scheduleDate >= today &&
          doc.data().siteId == store.state.currentSite
        ) {
          console.debug(scheduleDate, today);
          results.push({ ...doc.data(), id: doc.id });
        }
      }
      console.log(results);
      // update Route values
      allRoutes.value = results;
    });

    watchEffect((onInvalidate) => {
      onInvalidate(() => unsub());
    });

    if (props.collectionRequests.length > 1) {
      props.collectionRequests.forEach((cr) => {
        var addressObj = {
          address: cr.geotag,
          alias: cr.locationName
            ? cr.supplierName + " - " + cr.locationName
            : cr.supplierName,
          time: 30,
          addressStopType: "PICKUP",
          email: "string",
          weight: cr.estimatedVolume,
          isDepot: false,
          serviceTime: "string",
          refNo: cr.id,
        };
        addresses.value.push(addressObj);
      });
    } else {
      var addressObj = {
        address: props.collectionRequests[0].geotag,
        alias: props.collectionRequests[0].locationName
          ? props.collectionRequests[0].supplierName +
            " - " +
            props.collectionRequests[0].locationName
          : props.collectionRequests[0].supplierName,
        time: 30,
        addressStopType: "PICKUP",
        email: "string",
        weight: props.collectionRequests[0].estimatedVolume,
        isDepot: false,
        serviceTime: "string",
        refNo: props.collectionRequests[0].id,
      };
      singleAddress.value = addressObj;
    }
    // props.collectionRequests.forEach((cr) => {
    //   var addressObj = {
    //     address: cr.geotag,
    //     alias: cr.locationName
    //       ? cr.supplierName + " - " + cr.locationName
    //       : cr.supplierName,
    //     time: 30,
    //     addressStopType: "PICKUP",
    //     email: "string",
    //     weight: cr.estimatedVolume,
    //     isDepot: false,
    //     serviceTime: "string",
    //     refNo: cr.id,
    //   };
    //   addresses.value.push(addressObj);
    // });

    const dateList = computed(() => {
      const dates = [];
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const timeStampDateToday = new Date();
      timeStampDateToday.setHours(0, 0, 0, 0);
      timeStampDateToday.setDate(timeStampDateToday.getDate() + 1);
      // const timeStampToday = Timestamp.fromDate(today);
      dates.push({
        date: today.toDateString(),
        timestamp: timeStampDateToday.toISOString(),
      });
      for (let index = 1; index < 7; index++) {
        const day = new Date(today);
        day.setDate(day.getDate() + index);

        const timeStampDate = new Date(today);
        timeStampDate.setDate(timeStampDate.getDate() + index + 1);
        // const timeStampDay = Timestamp.fromDate(day);
        dates.push({
          date: day.toDateString(),
          timestamp: timeStampDate.toISOString(),
        });
      }
      return dates;
    });

    const handleClick = async () => {
      var route = {
        driverName: driverName.value,
        truckNumber: truckNumber.value,
        routeType: routeType.value,
        routeStart: routeStart.value,
        plannedDateISO: plannedDateIso.value,
        siteId: store.state.currentSite,
        addresses: addresses.value,
      };

      console.log(route);

      if (allowInsertRoute.value) {
        isPending.value = true;
        let url =
          store.state.apiUrl +
          "/insertAddressesToRoute" +
          "?route_id=" +
          selectedRouteId.value;
        console.log(url, singleAddress.value);
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(singleAddress.value),
        });
        isPending.value = false;
        const content = await response.json();
        if (content[1] == 200) {
          closeModal();
        }
      } else {
        let url = store.state.apiUrl + "/createRoute";
        isPending.value = true;
        console.log(route);
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(route),
        });
        isPending.value = false;
        const content = await response.json();
        if (content[1] == 200) {
          closeModal();
        }
      }
    };

    // Close modal
    const closeModal = () => {
      context.emit("close");
    };

    return {
      closeModal,
      handleClick,
      isPending,

      driverName,
      truckNumber,
      routeType,
      plannedDateIso,
      dateList,
      allowInsertRoute,
      allRoutes,
      selectedRouteId,
      routeStart,
      userSite: computed(() => store.state.currentSite),
    };
  },
};
</script>

<style>
</style>